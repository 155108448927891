import { useMutation } from "@apollo/client";
import { CREATE_PRODUCTION_PLAN } from "./graphql";
import { Input } from "components/shared/Form";
import { useState } from "react";
import Button from "components/shared/Button";
import { useNavigate } from "react-router-dom";
import { Alert } from "components/shared/Toast";

const CreateOrderView = ({}) => {
  const [name, setName] = useState("");
  const navigate = useNavigate();

  const [createProductionPlan, createProductionPlanRes] = useMutation(CREATE_PRODUCTION_PLAN, {
    variables: { name, createdByCustomer: true, startDate: new Date().addDays(7) },
    onCompleted(data) {
      navigate(`/orders/${data.createProductionPlan.productionPlan.id}`);
    },
    onError(error) {
      Alert("error", error.message);
    },
  });

  return (
    <div className="text-base">
      <div>
        <div className="flex items-center space-x-3">
          <label htmlFor="">Plan Name: </label>
          <Input className="w-full" value={name} onChange={(e) => setName(e.target.value)} />
        </div>
        <div className="mt-1 text-sm opacity-70">Name this plan for reference.</div>
      </div>

      <div className="mt-8">
        <Button onClick={createProductionPlan} primary loading={createProductionPlanRes.loading}>
          Create
        </Button>
      </div>
    </div>
  );
};

export default CreateOrderView;
