import { useMutation, gql } from "@apollo/client";
import { useState } from "react";
import { Button } from "components/shared/base";
import { Input, Text } from "components/shared/Form";
import { Alert } from "components/shared/Toast";

const CONTACT = gql`
  mutation CONTACT($email: String!, $message: String!) {
    contact(email: $email, message: $message) {
      message
    }
  }
`;

const ContactForm = ({ hide }) => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [contact, { loading }] = useMutation(CONTACT, {
    variables: { email, message },
    onCompleted: (data) => {
      Alert("success", data.contact.message);
      setEmail("");
      setMessage("");
      if (hide) hide();
    },
    onError: (error) => Alert("error", error.message),
  });

  return (
    <div>
      <div>
        <label className="block mb-2">Email</label>
        <Input className="flex flex-1 w-full" value={email} onChange={(e) => setEmail(e.target.value)} />
      </div>
      <div className="mt-4">
        <label className="block mb-2">Message</label>
        <Text value={message} onChange={(e) => setMessage(e.target.value)} />
      </div>
      <div className="my-6">
        <Button
          loading={loading}
          disabled={loading}
          bold
          title={loading ? "Submitting..." : "Submit"}
          color="blue"
          size="lg"
          onClick={() => {
            if (!email) return Alert("error", "Please provide your email.");
            if (!message) return Alert("error", "Please provide a message.");
            contact();
          }}
        />
      </div>
    </div>
  );
};

export default ContactForm;
