import { gql } from "@apollo/client";

export const REQUEST_LOGIN_CODE = gql`
  mutation REQUEST_LOGIN_CODE($email: String!) {
    requestLoginWithCode(email: $email) {
      success
    }
  }
`;

export const LOGIN_WITH_CODE = gql`
  mutation LOGIN_WITH_CODE($email: String!, $code: String!) {
    loginWithCode(email: $email, code: $code) {
      me {
        id
        email
        firstName
        lastName
      }
    }
  }
`;
