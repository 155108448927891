import React from "react";
import { Routes, Route } from "react-router-dom";
import OrderList from "./OrderList";
import OrderDetail from "./OrderDetail";

const OrdersPage = () => {
  return (
    <Routes>
      <Route index element={<OrderList />} />
      <Route path="create" element={<OrderDetail />} />
      <Route path=":id" element={<OrderDetail />} />
    </Routes>
  );
};

export default OrdersPage;
