import { useState } from "react";
import { useParams } from "react-router-dom";
import { useQuery, useMutation, gql } from "@apollo/client";
import writtenNumber from "written-number";
import Spinner from "../../components/shared/Spinner";
import Errors from "../../components/shared/Errors";
import Icon from "../../components/shared/Icon";
import { Button } from "../../components/shared/base";
import ShipmentStatus from "../../components/shared/ShipmentStatus";
import { Alert } from "../../components/shared/Toast";
import { createShipmentInvoicePoGroups } from "./utils";
import http from "../../utils/http";
import track from "../../utils/track";
import QCReports from "./QCreports";

const FETCH_SHIPMENT = gql`
  query($id: ID!) {
    shipment(id: $id) {
      id
      invoiceNumber
      commercialInvoiceId
      commercialInvoices {
        id
      }
      customer {
        id
        name
      }
      forwarder {
        id
        name
      }
      latestStatus
      addr
      shipmentConsigneeName
      deliveryAddr
      latestEtd
      latestEta
      dateOfDeliveryToPort
      loadingPort
      containerNumber
      blNumber
      vv
      notes
      shipmentInvoices {
        id
        invoice {
          id
          number
        }
        pos {
          id
          number
        }
        shipmentInvoiceRows {
          id
          purchaseOrder {
            id
            number
          }
          product {
            id
            name
            number
            outerCartonX
            outerCartonY
            outerCartonZ
            ctnGrossWeight
            ctnNetWeight
            quantityPerCarton
            outerCartonCbm
          }
          packingName
          qty
          totalCarton
          totalNetWeight
          totalGrossWeight
          totalCbm
        }
      }
      totalQty
      totalCarton
      totalNetWeight
      totalGrossWeight
      totalCbm
      attachments {
        id
        uri
        name
      }
      sharedQcReports {
        id
        name
        product {
          id
          name
          number
        }
        status
        checkQty
      }
    }
  }
`;

const REMIND_COMMERCIAL_INVOICE = gql`
  mutation($shipmentId: ID!) {
    remindCommercialInvoice(shipmentId: $shipmentId) {
      message
    }
  }
`;

const CREATE_DOCUMENT = gql`
  mutation($shipmentId: ID!, $docType: String!) {
    createDocument(shipmentId: $shipmentId, docType: $docType) {
      document {
        id
      }
    }
  }
`;

const ShipmentInvoiceRow = ({ row, highLight }) => {
  return (
    <>
      <tr className={highLight ? "bg-sky-200" : ""}>
        <td className="py-1">1-{row.totalCarton}</td>
        <td className="py-1">
          [ {row.product.number} ] {row.packingName}
        </td>
        <td className="py-1" align="right">
          {row.product.quantityPerCarton}
        </td>
        <td className="py-1" align="right">
          {row.product.ctnNetWeight}
        </td>
        <td className="py-1" align="right">
          {row.product.ctnGrossWeight}
        </td>
        <td className="py-1" align="right">
          {row.product.outerCartonCbm}
        </td>
      </tr>
      <tr className={`border-b ${highLight ? "bg-sky-200" : ""}`}>
        <td className="py-1" colSpan="2">
          (CTN SIZE: {row.product.outerCartonX} ✕{row.product.outerCartonY} ✕{row.product.outerCartonZ})
        </td>
        <td className="py-1" align="right">
          {row.qty}
        </td>
        <td className="py-1" align="right">
          {row.totalNetWeight}
        </td>
        <td className="py-1" align="right">
          {row.totalGrossWeight}
        </td>
        <td className="py-2" align="right">
          {row.totalCbm}
        </td>
      </tr>
    </>
  );
};

const ShipmentInvoiceGroup = ({ shipmentInvoice, group, highLight }) => {
  return group.shipmentInvoiceRows.map((row) => <ShipmentInvoiceRow key={row.id} shipmentInvoiceId={shipmentInvoice.id} row={row} highLight={highLight} />);
};

const ShipmentInvoice = ({ shipmentInvoice, hasMultifpleInvoices }) => {
  const poGroups = createShipmentInvoicePoGroups(shipmentInvoice);
  const { invoiceId } = useParams();
  const highLight = hasMultifpleInvoices ? invoiceId === shipmentInvoice.invoice.id : false;
  return (
    <>
      <tr className={highLight ? "bg-sky-600 text-white" : "bg-gray-100"}>
        <td colSpan="6" className="font-bold py-2">
          Invoice #{shipmentInvoice.invoice.number}
        </td>
      </tr>
      {poGroups.map((group, groupIndex) => (
        <ShipmentInvoiceGroup shipmentInvoice={shipmentInvoice} group={group} key={groupIndex} highLight={highLight} />
      ))}
    </>
  );
};

const ShipmentDetail = ({ shipmentId }) => {
  const { loading, error, data } = useQuery(FETCH_SHIPMENT, {
    variables: { id: shipmentId },
  });
  const [downloadingPackingList, setDownloadingPackingList] = useState(false);
  const [downloadingCommercialInvoice, setDownloadingCommercialInvoice] = useState(false);
  const [createDocument, createDocumentRes] = useMutation(CREATE_DOCUMENT, {
    variables: { shipmentId, docType: "packinglist" },
    onCompleted: (data) => {
      setDownloadingPackingList(true);
      http
        .get(`/doc/${data.createDocument.document.id}/`, {
          responseType: "blob",
        })
        .then((res) => {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `Packinglist #${shipment.invoiceNumber}.pdf`);
          document.body.appendChild(link);
          link.click();
          setDownloadingPackingList(false);
        })
        .catch((err) => {
          Alert("error", err.message);
          setDownloadingPackingList(false);
        });
      track("download packinglist", shipmentId);
    },
    onError: (error) => Alert("error", error.message),
  });

  const [remindCommercialInvoice, remindCommercialInvoiceRes] = useMutation(REMIND_COMMERCIAL_INVOICE, { variables: { shipmentId } });

  function downloadCommercialInvoice(id, filename) {
    setDownloadingCommercialInvoice(true);
    http
      .get(`/commercial-invoice/${id}/`, { responseType: "blob" })
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
        setDownloadingCommercialInvoice(false);
      })
      .catch((err) => {
        alert(err);
        setDownloadingCommercialInvoice(false);
      });
  }

  if (loading) return <Spinner />;
  if (error) return <Errors error={error} />;

  const shipment = data.shipment;

  return (
    <div className="space-y-8 text-sm">
      <div className="text-lg">
        <ShipmentStatus status={shipment.latestStatus} />
      </div>

      <div className="-mx-2">
        <table>
          <tbody>
            <tr>
              <td className="text-lg" />
            </tr>
            <tr>
              <td>
                <span className="font-bold">ETD</span>: {shipment.latestEtd}
              </td>
              <td>
                <span className="font-bold">Forwarder</span>: {shipment.forwarder ? shipment.forwarder.name : ""}
              </td>
            </tr>
            <tr>
              <td>
                <span className="font-bold">ETA:</span> {shipment.latestEta}
              </td>
              <td>
                <span className="font-bold">Container Number:</span> {shipment.containerNumber}
              </td>
            </tr>
            <tr>
              <td>
                <span className="font-bold">Date of Delivery Port:</span>{" "}
                {shipment.dateOfDeliveryToPort ? new Date(shipment.dateOfDeliveryToPort * 1000).toLocaleDateString() : " - "}
              </td>
              <td>
                <span className="font-bold">BL Number:</span> {shipment.blNumber}
              </td>
            </tr>
            <tr>
              <td>
                <span className="font-bold">Loading Port:</span> {shipment.loadingPort}
              </td>
              <td>
                <span className="font-bold">Vessel Name:</span> {shipment.vv}
              </td>
            </tr>
          </tbody>
        </table>

        <table className="mt-8 w-full border border-gray-100 text-sm">
          <thead className="bg-gray-700 text-white">
            <tr className="border-t border-b border-gray-100">
              <th className="p-2" align="left">
                CTN NO
              </th>
              <th className="p-2 whitespace-no-wrap" align="left">
                Goods Description
              </th>
              <th className="p-2 whitespace-no-wrap" align="right">
                QTY
              </th>
              <th className="p-2 whitespace-no-wrap" align="right">
                N.W.(kg)
              </th>
              <th className="p-2 whitespace-no-wrap" align="right">
                G.W.(kg)
              </th>
              <th className="p-2 whitespace-no-wrap" align="right">
                CBM(m³)
              </th>
            </tr>
          </thead>
          <tbody>
            {shipment.shipmentInvoices.map((i, index) => (
              <ShipmentInvoice hasMultifpleInvoices={shipment.shipmentInvoices.length > 1} key={"invoice" + index} shipmentInvoice={i} />
            ))}
            <tr className="font-semibold text-base">
              <td className="py-3" colSpan="2">
                TOTAL: {shipment.totalCarton}
              </td>
              <td className="py-3" align="right">
                {shipment.totalQty}
              </td>
              <td className="py-3" align="right">
                {shipment.totalNetWeight}
              </td>
              <td className="py-3" align="right">
                {shipment.totalGrossWeight}
              </td>
              <td className="py-3" align="right">
                {shipment.totalCbm}
              </td>
            </tr>
            <tr>
              <td className="py-2 uppercase" colSpan="6">
                {writtenNumber(shipment.totalCarton)} CARTONS ONLY.
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      {shipment.sharedQcReports.length > 0 ? (
        <>
          <hr />

          <div>
            <h4>QC Reports</h4>
            <div className="mt-2">
              <QCReports qcReports={shipment.sharedQcReports} />
            </div>
          </div>
        </>
      ) : null}

      <hr />

      <div className="px-2 flex space-x-8">
        <Button
          bold
          size="base"
          title={createDocumentRes.loading || downloadingPackingList ? "Downloading Packing List..." : "Download Packing List"}
          loading={createDocumentRes.loading || downloadingPackingList}
          disabled={createDocumentRes.loading || downloadingPackingList}
          onClick={createDocument}
        />

        {shipment.commercialInvoiceId ? (
          <Button
            bold
            size="base"
            loading={downloadingCommercialInvoice}
            disabled={downloadingCommercialInvoice}
            onClick={() => downloadCommercialInvoice(shipment.commercialInvoiceId, `Commercial Invoice #${shipment.invoiceNumber}.pdf`)}
            title={"Download Commercial Invoice"}
          />
        ) : (
          <Button
            bold
            size="base"
            title={remindCommercialInvoiceRes.loading ? "Processing..." : "Commercial Invoice Pending upload. Remind your supplier?"}
            loading={remindCommercialInvoiceRes.loading}
            disabled={remindCommercialInvoiceRes.loading}
            onClick={remindCommercialInvoice}
          />
        )}
      </div>

      <hr />

      {shipment.attachments.length > 0 && (
        <div className="w-full flex px-2">
          {shipment.attachments.map((f) => (
            <a className="text-center block break-words mr-4" style={{ maxWidth: 100 }} key={f.id} href={f.uri} rel="noopener noreferrer" target="_blank">
              <div className="text-5xl">
                <Icon icon="document" size={32} />
              </div>
              <div className="text-xs text-grey-darker text-bold whitespace-wrap">{f.name}</div>
            </a>
          ))}
        </div>
      )}
    </div>
  );
};

export default ShipmentDetail;
