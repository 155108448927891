import React, { useState } from "react";
import { useMutation, gql } from "@apollo/client";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import useRandomImage from "hooks/useRandomImage";
import { useNavigate } from "react-router-dom";
import BizLogo from "components/BizLogo";
import { Alert } from "components/shared/Toast";
import { Input, Select } from "components/shared/Form";
import { Button } from "components/shared/base";

const customerTypeS = ["retailer", "distributor", "sales_rep", "marketting_specialist"];

const REGISTER = gql`
  mutation REGISTER(
    $email: String!
    $password1: String!
    $password2: String!
    $firstName: String!
    $lastName: String!
    $company: String!
    $customerType: String!
    $country: String!
    $region: String!
    $city: String!
    $street: String!
  ) {
    register(
      email: $email
      password1: $password1
      password2: $password2
      firstName: $firstName
      lastName: $lastName
      company: $company
      customerType: $customerType
      country: $country
      region: $region
      city: $city
      street: $street
    ) {
      me {
        email
      }
    }
  }
`;

const RegisterPage = () => {
  const [state, setState] = useState({
    email: process.env.NODE_ENV === "production" ? "" : `${Math.random().toString(36).substring(7)}@wabobatest.com`,
    password1: "",
    password2: "",
    firstName: "",
    lastName: "",
    phone: "",
    company: "",
    customerType: "retailer",
    country: "",
    region: "",
    city: "",
    street: "",
    subscribe: false,
    agreeTermsPrivacy: false,
  });

  const randomPromoImageQuery = useRandomImage();
  const [subscribe, setSubscribe] = useState(false);
  const [agreeTermsPrivacy, setAgreeTermsPrivacy] = useState(false);
  const navigate = useNavigate();

  const [register, { loading }] = useMutation(REGISTER, {
    variables: state,
    onCompleted() {
      navigate("/");
    },
    onError: (error) => {
      Alert("error", error.message);
    },
    refetchQueries: ["FETCH_ME"],
    awaitRefetchQueries: true,
  });

  function onChange(data) {
    setState((prev) => ({ ...prev, ...data }));
  }

  function submit(e) {
    e.preventDefault();
    register();
  }

  return (
    <div
      className="w-full h-screen inset-0 bg-cover bg-center"
      style={
        randomPromoImageQuery.data && randomPromoImageQuery.data.randomPromoImage
          ? {
              backgroundImage: `url(${
                process.env.NODE_ENV == "development" ? randomPromoImageQuery.data.randomPromoImage.url : randomPromoImageQuery.data.randomPromoImage.url
              })`,
            }
          : null
      }
    >
      <div className="fixed bottom-0 right-0 text-xs">
        <div className="text-white p-3 text-right leading-normal" style={{ textShadow: "0 0 15px #00000075" }}>
          <p>Copyright © {new Date().getFullYear()} All Rights Reserved by Waboba</p>
        </div>
      </div>
      <div className="container-sm px-16 h-screen flex items-center">
        <div className="bg-white rounded-3xl p-8 w-full mx-auto" style={{ maxWidth: 700 }}>
          <BizLogo />

          <div className="text-center my-4 leading-normal">
            <p>Put a bounce in your sales!</p>
            <p>Sign up for Waboba Sales & Marketing Materials.</p>
          </div>
          <div>
            <form className="mt-6" onSubmit={submit}>
              <div className="flex -mx-2 mb-4">
                <Input
                  className="mx-2 flex-1"
                  type="text"
                  value={state.firstName}
                  placeholder="First Name"
                  onChange={(e) =>
                    onChange({
                      firstName: e.target.value,
                    })
                  }
                />
                <Input
                  className="mx-2 flex-1"
                  type="text"
                  value={state.lastName}
                  placeholder="Last Name"
                  onChange={(e) => onChange({ lastName: e.target.value })}
                />
              </div>
              <div className="flex -mx-2 mb-4">
                <Input className="mx-2 flex-1" type="text" value={state.email} placeholder="Email" onChange={(e) => onChange({ email: e.target.value })} />
                <Input className="mx-2 flex-1" type="text" value={state.phone} placeholder="Phone" onChange={(e) => onChange({ phone: e.target.value })} />
              </div>
              <div className="flex -mx-2 mb-4">
                <div className="mx-2 flex-1">
                  <Input
                    className="w-full"
                    type="text"
                    value={state.company}
                    placeholder="Company / Organization"
                    onChange={(e) =>
                      onChange({
                        company: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="mx-2 flex-1">
                  <Select
                    className="w-full"
                    value={state.customerType}
                    onChange={(e) =>
                      onChange({
                        customerType: e.target.value,
                      })
                    }
                  >
                    {customerTypeS.map((t) => (
                      <option key={t} value={t}>
                        {t}
                      </option>
                    ))}
                  </Select>
                </div>
              </div>
              <div className="flex -mx-2 mb-4">
                <div className="flex-1 mx-2">
                  <CountryDropdown
                    classes="w-full border p-2 rounded-lg border-gray-200 text-sm cursor-pointer rounded-lg appearance-none focus:border-blue-600 disabled:opacity-50 disabled:cursor-not-allowed"
                    value={state.country}
                    onChange={(v) => onChange({ country: v })}
                  />
                </div>
                <div className="flex-1 mx-2">
                  <RegionDropdown
                    blankOptionLabel="Select Country First"
                    classes="w-full border p-2 rounded-lg border-gray-200 text-sm cursor-pointer rounded-lg appearance-none focus:border-blue-600 disabled:opacity-50 disabled:cursor-not-allowed"
                    country={state.country}
                    value={state.region}
                    onChange={(v) => onChange({ region: v })}
                  />
                </div>
              </div>
              <div className="flex -mx-2 mb-4">
                <Input className="mx-2 flex-1" type="text" value={state.city} placeholder="city" onChange={(e) => onChange({ city: e.target.value })} />
                <Input className="mx-2 flex-1" type="text" value={state.street} placeholder="Street" onChange={(e) => onChange({ street: e.target.value })} />
              </div>
              <div className="flex -mx-2 mb-4">
                <Input
                  className="mx-2 flex-1"
                  type="password"
                  value={state.password1}
                  placeholder="Password"
                  onChange={(e) => onChange({ password1: e.target.value })}
                />
                <Input
                  className="mx-2 flex-1"
                  type="password"
                  value={state.password2}
                  placeholder="Confirm Password"
                  onChange={(e) => onChange({ password2: e.target.value })}
                />
              </div>
              <div className="flex items-center justify-start mb-2">
                <input
                  className="w-auto mr-2"
                  type="checkbox"
                  checked={agreeTermsPrivacy}
                  id="agree-terms-privacy-check-box"
                  onChange={(e) => setAgreeTermsPrivacy(e.target.checked)}
                />
                <label htmlFor="agree-terms-privacy-check-box" className="font-normal flex items-center">
                  Aagree with our <Button className="ml-2" link="/terms-privacy" title="terms and privacy policy" />.
                </label>
              </div>
              <div className="flex items-center justify-start mb-4">
                <input className="w-auto mr-2" type="checkbox" checked={subscribe} id="subscribe-check-box" onChange={(e) => setSubscribe(e.target.checked)} />
                <label htmlFor="subscribe-check-box" className="font-normal">
                  Receive emails about our latest products and marketing materials.
                </label>
              </div>
              <div className="flex justify-center">
                <Button type="submit" disabled={loading || !agreeTermsPrivacy} title={loading ? "Signing up..." : "Sign me up!"} />
              </div>

              <div className="flex justify-center mt-12 text-sm">
                <Button link="/login" title="Already has an account? Go to Login." />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegisterPage;
