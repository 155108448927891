import { gql, useQuery } from "@apollo/client";
import Button from "components/shared/Button";
import ContainerizationView from "components/shared/ContainerizationView";
import { Input, Text } from "components/shared/Form";
import AlsoLikeView from "pages/products/AlsoLikeView";
import { useEffect, useState } from "react";

const FETCH_COLLECTIONS = gql`
  query FETCH_COLLECTIONS {
    anyCollection(name: "Catalog 2024") {
      id
      children {
        id
        name
        products: productLines {
          id
          name
          number
          mainImage(size: "300x300")
          activeForBiz
        }
        children {
          id
          name
          products: productLines {
            id
            name
            number
            mainImage(size: "300x300")
            activeForBiz
          }
          children {
            id
            name
            products: productLines {
              id
              name
              number
              mainImage(size: "300x300")
              activeForBiz
            }
          }
        }
      }
    }
  }
`;

function getRandomProducts(products, number) {
  let randomProducts = [];
  for (let i = 0; i < number; i++) {
    let randomIndex = Math.floor(Math.random() * products.length);
    randomProducts.push(products[randomIndex]);
  }
  return randomProducts;
}

const Cart = () => {
  const { data } = useQuery(FETCH_COLLECTIONS);
  const [cartLines, setCartLines] = useState([]);

  useEffect(() => {
    if (data) {
      let products = [];
      data.anyCollection.children.forEach((collection) => {
        products = [...products, ...collection.products];
        collection.children.forEach((subCollection) => {
          products = [...products, ...subCollection.products];
          subCollection.children.forEach((subSubCollection) => {
            products = [...products, ...subSubCollection.products];
          });
        });
      });
      products = getRandomProducts(
        products.filter((i) => i.activeForBiz),
        10
      );
      setCartLines(
        products.map((product) => ({
          product,
          quantity: Math.floor(Math.random() * 1000),
          total: Math.floor(Math.random() * 1000),
        }))
      );
    }
  }, [data]);

  if (!data) return null;

  let products = [];
  data.anyCollection.children.forEach((collection) => {
    products = [...products, ...collection.products];
    collection.children.forEach((subCollection) => {
      products = [...products, ...subCollection.products];
      subCollection.children.forEach((subSubCollection) => {
        products = [...products, ...subSubCollection.products];
      });
    });
  });

  const randomProducts = getRandomProducts(
    products.filter((i) => i.activeForBiz),
    4
  );
  return (
    <div className="">
      <div className="m-10">
        <h1>Shopping Cart</h1>
        <div className="mt-10 grid grid-cols-12 gap-8">
          <div className=" col-span-8 space-y-8">
            <div className="card">
              <h4>Cart Items</h4>
              <div className="mt-4">
                {cartLines.map((cartLine, index) => (
                  <div className="flex items-center space-x-4 border-t border-gray-100 py-2" key={index}>
                    <img src={cartLine.product.mainImage} className="w-20" />
                    <div className="flex-1">
                      <div className="font-semibold">{cartLine.product.name}</div>
                      <div className="text-gray-600">{cartLine.product.number}</div>
                    </div>
                    <div className="flex items-center space-x-2 pr-6">
                      <button>-</button>
                      <div>
                        <Input className="text-center w-20" value={cartLine.quantity} />
                      </div>
                      <button>+</button>
                    </div>
                    <div className="text-right">${cartLine.total}</div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className="col-span-4 space-y-8">
            <div className="card">
              <h4>Capacity</h4>
              <div className="mt-4">
                <ContainerizationView totalCbm={100} />
              </div>
            </div>

            <div className="card">
              <h4>Delivery</h4>
              <div className="mt-2">
                <div>
                  Estimate ready date is <b>2024-02-02</b>.
                </div>
                <div className="mt-2 opacity-70">
                  The estimated ready date is computed base on our inventory and production capacity. The actual ready date might be +/- 7 days. Since our
                  inventory is dynamic, the estimated ready date might change until you request a quotation.
                </div>
              </div>
            </div>

            <div className="card">
              <div>Once you are ready to place order, you can request a quotation. We will review your order and confirm this order with you.</div>
              <div className="mt-4">
                <label htmlFor="">Order Note:</label>
                <Text className="mt-2" />
              </div>
              <div className="mt-4 flex">
                <Button primary>Request Quotation</Button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <AlsoLikeView /> */}
    </div>
  );
};

export default Cart;
