import { useState } from "react";
import { TbBuildingFactory } from "react-icons/tb";
import { MdHomeWork } from "react-icons/md";
import { RiMoneyDollarCircleLine, RiShip2Line, RiTruckLine } from "react-icons/ri";
import { BiMapPin } from "react-icons/bi";

function ShipmentStatus({ className = "", status }) {
  let icon = null;
  let textColor = "text-gray-700";
  switch (status) {
    case "waiting for deposit payment":
      icon = <RiMoneyDollarCircleLine size={21} />;
      textColor = "text-yellow-600";
      break;
    case "in production":
      icon = <MdHomeWork size={21} />;
      textColor = "text-green-500";
      break;
    case "delivered to port":
      icon = <RiTruckLine size={21} />;
      textColor = "text-indigo-500";
      break;
    case "shipping":
      icon = <RiShip2Line size={21} />;
      textColor = "text-purple-600";
      break;
    case "arrived at destination":
      icon = <BiMapPin size={21} />;
      textColor = "text-teal-600";
      break;
    case "on hold":
      textColor = "text-pink-500";
      break;
    case "canceled":
      textColor = "text-red-500";
      break;
    case "-":
      textColor = "text-gray-500";
      break;
    default:
      break;
  }
  return (
    <div className={`${className} ${textColor} flex items-center`}>
      {icon}
      <span className="ml-2 whitespace-nowrap capitalize">{status}</span>
    </div>
  );
}

export default ShipmentStatus;

export function CheckableShipmentStatus({ className = "", status, check, onSelect }) {
  const [selected, setSelected] = useState(check);
  return (
    <button
      onClick={(_) => {
        const updatedSelected = !selected;
        setSelected(updatedSelected);
        if (onSelect) onSelect(updatedSelected);
      }}
      className={`${className} ${!selected ? "opacity-25 hover:opacity-50 active:opacity-100" : "opacity-100"} cursor-pointer flex animate-ease-1`}
    >
      <ShipmentStatus status={status} check={selected} />
    </button>
  );
}
