import React, { useState, forwardRef } from "react";
import TextareaAutosize from "react-autosize-textarea";
import DayPickerInput from "react-day-picker/DayPickerInput";
import { Tag } from "./base";
import { uuidv4 } from "./uuid.js";

const height = 32;

export const Input = ({ className = "", validated = true, label, value, ...rest }) => (
  <input
    className={`px-2 text-sm rounded-lg appearance-none border border-gray-100
         ${validated ? "focus:border-sky-600" : "border-red-500"} ${className} `}
    style={{ height }}
    value={value}
    {...rest}
  />
);

export const Switcher = ({ className = "", isOn, onChange }) => {
  return (
    <div
      className={`
            flex items-center rounded-full bg-white cursor-pointer relative
            ${isOn ? "bg-green-500" : "bg-gray-300"} animate-ease-2
            ${className}
            `}
      style={{ width: 52, height: 30 }}
      onClick={onChange}
    >
      <div style={{ width: 26, height: 26, left: isOn ? 24 : 2 }} className="rounded-full animate-ease-2 absolute bg-white"></div>
    </div>
  );
};

export const TapSelector = ({ className = "", options, value, onChange }) => {
  const [selected, setSelected] = useState(value);
  return (
    <div className={`flex items-center rounded-full bg-white cursor-pointer relative ${className}`}>
      <div className="flex">
        {options.map((i, index) => (
          <Tag
            key={index}
            selected={selected === i}
            className={`animate-ease-2 mr-4`}
            title={i}
            onClick={() => {
              if (!onChange) return;
              setSelected(i);
              onChange(i);
            }}
          />
        ))}
      </div>
    </div>
  );
};

export const MultipleTapSelector = ({ className = "", options, value, onChange }) => {
  const [selected, setSelected] = useState(value);
  return (
    <div className={`flex items-center rounded-full bg-white relative ${className}`}>
      <div className="flex flex-wrap">
        {options.map((i, index) => (
          <Tag
            key={index}
            selected={selected.indexOf(i) > -1}
            className={`animate-ease-2 mr-4 mb-2`}
            title={i}
            onClick={() => {
              if (!onChange) return;
              let res = [...selected];
              if (selected.indexOf(i) > -1) {
                res = selected.filter((prevItem) => prevItem !== i);
              } else {
                res = [...selected, i];
              }
              setSelected(res);
              onChange(res);
            }}
          />
        ))}
      </div>
    </div>
  );
};

export const Select = ({ className = "", textAlignLast = "", plain = false, children, ...rest }) => (
  <select
    className={`${className} text-sm cursor-pointer
         ${
           plain ? "" : "border border-gray-100"
         } rounded-lg appearance-none px-4 focus:border-sky-600 disabled:opacity-50 disabled:cursor-not-allowed max-w-full`}
    style={{ height, textAlignLast }}
    {...rest}
  >
    {children}
  </select>
);

export const Text = ({ className = "", ...rest }) => (
  <TextareaAutosize
    rows={3}
    className={`${className} w-full px-3 py-2 text-sm rounded-xl appearance-none border border-gray-200 focus:border-sky-600`}
    {...rest}
  />
);

const CustomDateInput = (props) => <Input className="text-center" {...props} />;

export const DatePicker = ({ className = "", ...rest }) => (
  <DayPickerInput
    classNames={{
      container: `${className} relative`,
      overlay: "leading-none shadow-lg rounded-lg absolute bg-white z-10",
    }}
    component={forwardRef(CustomDateInput)}
    {...rest}
  />
);

export const FileSelector = ({ className = "", title = "add files", bold = false, bgColor = "", onChange, uploading, ...rest }) => {
  const [mouseIn, setMouseIn] = useState(false);
  const [uid] = useState(uuidv4());
  const borderStyle = "text-sky-600";
  const hoverBorderStyle = "text-sky-700";
  return (
    <div
      className={`${className} overflow-hidden relative`}
      onMouseEnter={(_) => {
        setMouseIn(true);
      }}
      onMouseLeave={(_) => {
        setMouseIn(false);
      }}
    >
      <label
        className={`
               cursor-pointer relative
               ${bold ? "font-bold" : "font-normal"}
               ${borderStyle}
               ${mouseIn ? hoverBorderStyle : ""}
               ${bgColor}
               `}
        htmlFor={uid}
      >
        {uploading ? "uploading..." : title}
      </label>
      <input id={uid} className="hidden" type="file" multiple required onChange={onChange} disabled={uploading} {...rest} />
    </div>
  );
};
