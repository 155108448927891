import React from "react";
import { useQuery, gql } from "@apollo/client";
import { Link } from "react-router-dom";
import { Button } from "../components/shared/base";
import Spinner from "../components/shared/Spinner";
import Errors from "../components/shared/Errors";

const FETCH_FEATURED_PRODUCTS = gql`
  query FEATURED_PRODUCTS($slugs: [String!]) {
    products: featureProductLines(slugs: $slugs) {
      id
      name
      mainImage(size: "300x300")
      slug
    }
  }
`;

const ProductLink = ({ image, name, url }) => (
  <Link to={url} className="flex flex-col justify-center mb-4 text-blue" style={{ flexBasis: 200 }}>
    <div
      className="bg-contain bg-center bg-no-repeat"
      style={{
        backgroundImage: `url(${image})`,
        width: 200,
        height: 200,
        maxWidth: "100%",
      }}
    />
    <span className="text-base text-center my-2">{name}</span>
  </Link>
);

const products = [
  ["flungle", "mini-moon", "moonshine", "waboba-mini"],
  ["wingman", "dunk-or-dive", "flobo", "big-wing"],
  ["tropical-paddle-set", "sporty-paddle-set", "tropical-water-football", "sporty-football", "tropical-small-water-football", "sporty-small-football"],
  ["tropical-beach-soccer-ball", "sporty-beach-soccer-ball", "tropical-water-volleyball", "sporty-water-volleyball"],
  // ["tropical-paddle-set", "tropical-small-water-football", "tropical-water-football", "tropical-water-volleyball", "tropical-beach-soccer-ball"],
  // ["sporty-paddle-set", "sporty-football", "sporty-small-football", "sporty-water-volleyball", "sporty-beach-soccer-ball"],
];

const socialImages = [
  "https://cdn.waboba.com/public/biz/landing2024/2024-1.jpg",
  "https://cdn.waboba.com/public/biz/landing2024/2024-2.jpg",
  "https://cdn.waboba.com/public/biz/landing2024/2024-3.jpg",
  "https://cdn.waboba.com/public/biz/landing2024/2024-4.jpg",
  "https://cdn.waboba.com/public/biz/landing2024/2024-5.jpg",
  "https://cdn.waboba.com/public/biz/landing2024/2024-6.jpg",
];

const Landing2024 = () => {
  return (
    <div>
      <div className="w-full relative overflow-hidden bg-cover bg-center max-h-screen aspect-video">
        <video
          autoPlay
          loop
          muted
          playsInline
          width="1280"
          height="720"
          preload="auto"
          className="absolute"
          style={{
            minWidth: "100%",
            minHeight: "100%",
            width: "auto",
            height: "auto",
            top: "50%",
            left: "50%",
            transform: "translate(-50%,-50%)",
          }}
        >
          <source src="https://cdn.waboba.com/public/biz/landing2024/FlungleBiz.mp4" type="video/mp4" />
        </video>
        <div className="absolute inset-0 flex flex-col items-center justify-center hidden">
          <h1 className="text-6xl text-white">AIRLYFT</h1>
          <div className="mt-4 text-white text-2xl">Hollow Glider for Backyard Fun!</div>
          <Button className="mt-8 border border-white px-6 py-2" bold link="/products/airlyft" title="Learn More" color="white" size="lg" />
        </div>
      </div>

      <div className="mb-8 p-4 bg-white">
        <h2 className="text-4xl text-center my-16">NEW PRODUCTS FOR 2024</h2>
        <FeatureProducts groups={products} />
      </div>

      <SocialImages images={socialImages} />
    </div>
  );
};

const SocialImages = ({ images }) => (
  <div className="flex flex-wrap px-2">
    {images.map((i, index) => (
      <div key={index} className="p-2 relative w-full md:w-1/3 h-20" style={{ height: "26vw" }}>
        <div
          className="relative bg-cover bg-no-repeat bg-center w-full h-full"
          style={{
            backgroundImage: `url(${i})`,
          }}
        />
      </div>
    ))}
  </div>
);

const FeatureProducts = ({ groups }) => {
  const slugs = groups.flatMap((i) => i);
  const { loading, error, data } = useQuery(FETCH_FEATURED_PRODUCTS, {
    variables: { slugs },
  });

  if (loading) return <Spinner />;
  if (error) return <Errors error={error} />;

  return (
    <div className="mx-auto flex flex-col p-4 flex-wrap items-center justify-center">
      {groups.map((group, groupIndex) => (
        <div key={groupIndex} className="mt-4 flex flex-wrap justify-center">
          {group.map((slug, index) => {
            const product = data.products.find((i) => i.slug === slug);
            if (!product) return <div>{slug}</div>;
            return <ProductLink key={index} image={product.mainImage} name={product.name} url={`/products/${product.slug}`} />;
          })}
        </div>
      ))}
    </div>
  );
};

export default Landing2024;
