import { gql, useQuery } from "@apollo/client";

const FETCH_COLLECTIONS = gql`
  query FETCH_COLLECTIONS {
    anyCollection(name: "Catalog 2024") {
      id
      children {
        id
        name
        products: productLines {
          id
          name
          slug
          mainImage(size: "300x300")
          activeForBiz
        }
        children {
          id
          name
          products: productLines {
            id
            name
            slug
            mainImage(size: "300x300")
            activeForBiz
          }
          children {
            id
            name
            products: productLines {
              id
              name
              slug
              mainImage(size: "300x300")
              activeForBiz
            }
          }
        }
      }
    }
  }
`;

function getRandomProducts(products, number) {
  let randomProducts = [];
  for (let i = 0; i < number; i++) {
    let randomIndex = Math.floor(Math.random() * products.length);
    randomProducts.push(products[randomIndex]);
  }
  return randomProducts;
}

export const AlsoLikeView = () => {
  const { data } = useQuery(FETCH_COLLECTIONS);

  if (!data) return null;

  let products = [];
  data.anyCollection.children.forEach((collection) => {
    products = [...products, ...collection.products];
    collection.children.forEach((subCollection) => {
      products = [...products, ...subCollection.products];
      subCollection.children.forEach((subSubCollection) => {
        products = [...products, ...subSubCollection.products];
      });
    });
  });

  products = getRandomProducts(
    products.filter((i) => i.activeForBiz),
    4
  );

  console.log("products", products);

  return (
    <div className="py-8 pt-16 bg-white">
      <div className="text-center text-4xl font-bold">You May Also Like</div>
      <div className="flex justify-center space-x-6 mt-16 overflow-auto">
        {products.map((product) => (
          <div key={product.id} className="flex flex-col items-center flex-shrink-0 pb-16">
            <img src={product.mainImage} alt={product.name} className="object-contain w-60" />
            <h4>{product.name}</h4>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AlsoLikeView;
