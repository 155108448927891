import { toast } from "react-toastify";

const options = {
   autoClose: 3000,
   hideProgressBar: true,
   closeButton: false,
   className: "rounded-xl shadow-none text-white blur-20 font-bold",
   bodyClassName: "px-4"
};

export function Alert(type, message, extraOptions = {}) {
   const finalOptions = { ...options, ...extraOptions };
   switch (type) {
      case toast.TYPE.SUCCESS:
         toast(message, {
            ...finalOptions,
            className: `${finalOptions.className} bg-green-500`
         });
         break;
      case toast.TYPE.ERROR:
         toast(message, {
            ...finalOptions,
            className: `${finalOptions.className} bg-red-500`
         });
         break;
      default:
         break;
   }
}

export function createGraphqlErrorMessage(error) {
   var message;
   if (error.graphQLErrors) {
      message = error.graphQLErrors.map(err => err.message).join(",");
   }
   if (!message) message = error.message;
   return message;
}
