import React from "react";
import styled from "styled-components";
import ItemSize from "../../components/ItemSize";

const Cell = styled.div`
  padding: 0 10px;
  border-bottom: 1px solid #ddd;
  white-space: nowrap;
  height: 40px;
  line-height: 40px;
`;

function getHsCode(productLine) {
  if (!productLine) return "";
  return `${productLine.hsCode} ${productLine.hsCodeForEu ? `EU: ${productLine.hsCodeForEu}` : ""} ${
    productLine.hsCodeForUs ? `US: ${productLine.hsCodeForUs}` : ""
  }`;
}

function getMaterials(productLine) {
  if (productLine && productLine.children) {
    if (productLine.children.length > 0) {
      return productLine.children.map((i) => `- ${i.name}: ${i.materials.map((j) => j.material.name).join(", ")}`).join("\n");
    }
    return productLine.materials.map((i) => i.material.name).join(", ");
  }
  return " - ";
}

function getPanTone(productLine) {
  if (!productLine) return "";
  if (productLine.children.length > 0) {
    return productLine.children.map((i) => `- ${i.name}: ${i.pantone}`).join("\n");
  }
  return productLine.pantone;
}

const FIELDS = [
  "Item",
  "Item Number",
  "Product Weight",
  "Product Size",
  "HS Code",
  "Product Materials",
  "Pantone",
  "MOQ",
  "Packing",
  "Inner Box/Clam Shel Size",
  "Barcode EAN",
  "Barcode UPC",
  "Quantity per Displaybox",
  "Display Box Size",
  "Inner Carton Size",
  "Outer Carton Size",
  "CTN Net Weight",
  "CTN Gross Weight",
  "Outer Carton CMB",
  "Quantity per Carton",
];

const ProductData = ({ items, productLine }) => {
  return (
    <div className="flex relative overflow-auto border-x">
      <table className="w-full whitespace-nowrap">
        <tbody>
          {FIELDS.map((field, index) => (
            <tr key={index}>
              <td className="border-y sticky left-0 z-10 bg-gray-50 whitespace-nowrap">{field}</td>
              {items.map((i) => (
                <td key={i.id} className="border">
                  {field === "Item" ? i.name : ""}
                  {field === "Item Number" ? i.number : ""}
                  {field === "Product Weight" ? `${i.itemWeight} g` : ""}
                  {field === "Product Size" ? <ItemSize {...i} /> : ""}
                  {field === "HS Code" ? getHsCode(productLine) : ""}
                  {field === "Product Materials" ? getMaterials(productLine) : ""}
                  {field === "Pantone" ? getPanTone(productLine) : ""}
                  {field === "MOQ" ? i.moq : ""}
                  {field === "Packing" ? i.packing : ""}
                  {field === "Inner Box/Clam Shel Size" ? <ItemSize shape="cube" cubeX={i.innerBoxX} cubeY={i.innerBoxY} cubeZ={i.innerBoxZ} unit="mm" /> : ""}
                  {field === "Barcode EAN" ? `Inner Box: ${i.innerBoxBarcodeEan || " - "} Outer Carton: ${i.outerCartonBarcodeEan || " - "}` : ""}
                  {field === "Barcode UPC" ? `Inner Box: ${i.innerBoxBarcodeUpc || " - "} Outer Carton: ${i.outerCartonBarcodeUpc || " - "}` : ""}
                  {field === "Quantity per Displaybox" ? i.quantityPerDisplayBox : ""}
                  {field === "Display Box Size" ? <ItemSize shape="cube" cubeX={i.displayBoxX} cubeY={i.displayBoxY} cubeZ={i.displayBoxZ} unit="mm" /> : ""}
                  {field === "Inner Carton Size" ? (
                    <ItemSize shape="cube" cubeX={i.innerCartonX} cubeY={i.innerCartonY} cubeZ={i.innerCartonZ} unit="cm" />
                  ) : (
                    ""
                  )}
                  {field === "Outer Carton Size" ? (
                    <ItemSize shape="cube" cubeX={i.outerCartonX} cubeY={i.outerCartonY} cubeZ={i.outerCartonZ} unit="cm" />
                  ) : (
                    ""
                  )}
                  {field === "CTN Net Weight" ? `${i.ctnNetWeight} kg` : ""}
                  {field === "CTN Gross Weight" ? `${i.ctnGrossWeight || " - "} kg` : ""}
                  {field === "Outer Carton CMB" ? `${i.outerCartonCbm} m³` : ""}
                  {field === "Quantity per Carton" ? i.quantityPerCarton : ""}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ProductData;
