import { gql, useMutation } from "@apollo/client";
import Button from "components/shared/Button";
import { Text } from "components/shared/Form";
import { Alert } from "components/shared/Toast";
import { useState } from "react";

const CONTACT = gql`
  mutation CONTACT($email: String!, $message: String!) {
    contact(email: $email, message: $message) {
      message
    }
  }
`;

const ContactView = ({ hide, email }) => {
  const [content, setContent] = useState("");

  console.log("email", email);

  const [contact, contactRes] = useMutation(CONTACT, {
    onCompleted: () => {
      Alert("success", "Message sent successfully. We will get back to you as soon as possible.");
      hide();
    },
    onError: (error) => {
      Alert("error", error.message);
    },
  });

  return (
    <div>
      <div>
        <Text value={content} onChange={(e) => setContent(e.target.value)} placeholder="Type your message here" rows={12} />
      </div>

      <div className="mt-6">
        <Button primary send onClick={() => contact({ variables: { email, message: content } })} loading={contactRes.loading}>
          Submit
        </Button>
      </div>
    </div>
  );
};

export default ContactView;
