import React from "react";
import { Link } from "react-router-dom";

function BackButton({ size, title, color, to, onClick, ...rest }) {
  const style = {
    width: size ? size : 24,
    height: size ? size : 24,
  };
  const backImage = (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
      <path d="M217.9 256L345 129c9.4-9.4 9.4-24.6 0-33.9-9.4-9.4-24.6-9.3-34 0L167 239c-9.1 9.1-9.3 23.7-.7 33.1L310.9 417c4.7 4.7 10.9 7 17 7s12.3-2.3 17-7c9.4-9.4 9.4-24.6 0-33.9L217.9 256z" />
    </svg>
  );

  if (to) {
    return (
      <Link className="cursor-pointer animate-ease-2 flex items-center text-base" to={to} {...rest}>
        <div className="w-8 h-8 fill-current">{backImage}</div>
        {title ? title : null}
      </Link>
    );
  }
  return (
    <div className="block cursor-pointer animate-ease-2" style={style} onClick={onClick}>
      {backImage}
    </div>
  );
}

export default BackButton;
