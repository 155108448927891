import React, { useContext } from "react";
import { useMutation, gql } from "@apollo/client";
import { Link, NavLink } from "react-router-dom";
import BizLogo from "./BizLogo";
import { AppContext } from "App";
import { useNavigate } from "react-router-dom";
import Button from "./shared/Button";
import Dropdown from "./shared/DropDown";

const LOGOUT = gql`
  mutation LOGOUT {
    logout {
      isLogout
    }
  }
`;

const NavigationLink = ({ children, ...rest }) => (
  <NavLink
    className={({ isActive }) =>
      `hover:text-sky-600 active:text-sky-700 flex justify-center items-center
      ${isActive ? "text-sky-600" : ""}`
    }
    {...rest}
  >
    {children}
  </NavLink>
);

const MainNav = () => {
  const { user, setUser } = useContext(AppContext);
  const naviagte = useNavigate();

  const [logout, logoutRes] = useMutation(LOGOUT, {
    onCompleted: () => {
      naviagte("/login?logout");
      setUser(null);
    },
  });

  return (
    <div>
      {process.env.REACT_APP_STAGE !== "production" ? <div className=" bg-pink-600 text-white text-center font-bold py-1">Test Site</div> : null}

      <div className="flex items-stretch flex-1 justify-between relative bg-gray-900 text-white">
        <div className="ml-6 py-2 relative z-10 cursor-pointer" onClick={() => naviagte("/landing")}>
          <BizLogo dark height={20} />
        </div>
        <div className="absolute flex-1 flex w-full h-full items-stretch justify-center space-x-4 lg:space-x-8 xl:space-x-12">
          <NavigationLink to="/products">Products</NavigationLink>
          <NavigationLink to="/catalog">Catalog</NavigationLink>
          <NavigationLink to="/lifestyle-images">Lifestyle Images</NavigationLink>
          <NavigationLink to="/logos">Logos</NavigationLink>
          <NavigationLink to="/videos">Videos</NavigationLink>
          {/* <NavigationLink to="/orders">Orders</NavigationLink> */}
          <NavigationLink to="/shipments">Shipments</NavigationLink>
          <NavigationLink to="/contact">Contact</NavigationLink>
        </div>
        {user ? (
          <div className="relative mr-6">
            <Dropdown label={<div>{user.email}</div>}>
              <div className="px-6 py-2 border-gray-100 border-t first:border-t-0 hover:text-blue-600 active:text-blue-700 cursor-pointer">
                <Button onClick={logout} loading={logoutRes.loading}>
                  Sign Out
                </Button>
              </div>
            </Dropdown>
          </div>
        ) : (
          <div className="relative z-1 mr-6 flex items-center">
            <Link className="cursor-pointer font-semibold text-sky-600" to="/login">
              Sign In
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

export default MainNav;
