import React, { useState } from "react";
import { useMutation, gql } from "@apollo/client";
import PageTitle from "../../components/PageTitle";
import { Button } from "../../components/shared/base";
import { Input, Text } from "../../components/shared/Form";
import { Alert } from "../../components/shared/Toast";
import ContactForm from "components/forms/Contact";

export default () => (
   <div>
      <PageTitle title="Contact us" subtitle="We are here to listen." />

      <div className="container mx-auto max-w-md mt-8">
         <div className="text-center p-8">
            If you need any service or support, please send us a message and we
            will contact you as soon as possible.
         </div>

         <ContactForm />
      </div>
   </div>
);
