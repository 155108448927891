import React, { useState } from "react";
import { useQuery, gql } from "@apollo/client";
import Spinner from "components/shared/Spinner";
import Errors from "components/shared/Errors";
import Modal from "components/shared/Modal";
import http from "../../utils/http.js";
import { useModals } from "components/shared/ModalProvider.js";
import PageTitle from "components/PageTitle.js";

const FETCH_VIDEOS = gql`
  {
    allVideos {
      id
      name
      description
      active
      sources {
        id
        thumbSrc
        convertedVideoSrc
        height
        sourceUrl
      }
    }
  }
`;

const Videos = () => {
  const { loading, error, data } = useQuery(FETCH_VIDEOS);
  const modal = useModals();

  if (loading) return <Spinner />;
  if (error) return <Errors error={error} />;

  const videos = data.allVideos.filter((i) => i.active);

  return (
    <div className="flex flex-1 flex-col">
      <PageTitle title="Waboba Videos" />
      <div className="flex flex-wrap justify-start mt-8 mx-8 flex-1">
        {videos.map((video, index) => (
          <Video
            onClick={() =>
              modal.present({
                fullscreen: true,
                children: <PreviewVideo video={video} exit={modal.hide} />,
              })
            }
            key={index}
            video={video}
          />
        ))}
      </div>
    </div>
  );
};

const Video = ({ video, onClick }) => {
  const thumb = video.sources.length > 0 ? video.sources[0].thumbSrc : null;

  return (
    <div className="flex flex-col p-4 mb-4 w-full lg:w-1/2 xl:w-1/3">
      <div className="relative video-thumb cursor-pointer" onClick={onClick}>
        <div
          className="w-full bg-cover bg-center"
          style={{
            paddingBottom: "56.25%",
            backgroundImage: `url(${thumb})`,
          }}
        />
        <div className="video-overlay pin absolute flex items-center justify-center opacity-0">
          <span className="text-white text-4xl">▶</span>
        </div>
      </div>

      <span className="text-lg my-4 mb-2 font-bold">{video.name}</span>

      {video.description ? <p className="leading-normal mb-4 text-gray-700">{video.description}</p> : null}

      <div className="flex-1 border-b flex space-x-4 pb-2">
        {video.sources.map((source) => (
          <VideoSource key={source.id} source={source} />
        ))}
      </div>
    </div>
  );
};

const VideoSource = ({ source }) => {
  return (
    <div className="flex space-x-2 items-center">
      <a
        onClick={(_) => http.post("/an/", { name: "download video" })}
        href={source.sourceUrl}
        target="_blank"
        download
        rel="noopener noreferrer"
        style={{ width: "fit-content" }}
        className="text-sky-600"
      >
        Download {source.height}p
      </a>
    </div>
  );
};

const PreviewVideo = ({ video }) => {
  const source = video.sources.length > 0 ? video.sources[0] : null;

  if (source === null) return null;

  return (
    <div className="p-10 lg:p-24 flex-1 flex flex-col justify-center items-center">
      <div className="-translate-y-6 w-full">
        <h1>{video.name}</h1>
        <video className="w-full max-h-full mt-6" src={source.convertedVideoSrc} autoPlay controls />
      </div>
    </div>
  );
};

export default Videos;
