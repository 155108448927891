import { Button } from "components/shared/base";
import { GoShieldCheck } from "react-icons/go";

const TestReportView = ({ product }) => {
  if (!product.productList || product.productList.testReports === 0) return null;

  return (
    <div className="bg-white border-t border-gray-100 py-10">
      <div className="container mx-auto px-8">
        <h2>TEST REPORTS</h2>
        <div className="mt-4">
          {product.productList.testReports.map((testReport, index) => (
            <div key={index} className="flex items-center justify-between py-4 border-b border-gray-100 dark:border-gray-700 space-x-3">
              <div className="flex items-center space-x-3">
                <GoShieldCheck className="text-green-600 text-xl" />
                <div className="font-semibold tracking-wider">{testReport.standard.name}</div>
              </div>

              <div className="flex space-x-4">
                {testReport.fileList.map((file, index) => (
                  <Button key={index} title={testReport.fileList.length > 1 ? `Download ${index + 1}` : "Download"} link={file} target="_blank" />
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TestReportView;
