import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const BannerView = ({ images, initialIndex = 0 }) => {
  return (
    <Slider className="w-full" initialSlide={initialIndex} dots={false} autoplay={true} speed={1000} pauseOnHover={false} arrows={false}>
      {images.map((i) => (
        <div key={i.id}>
          <img className="object-cover aspect-[3/1] w-full" src={i.url} alt={i.url} />
        </div>
      ))}
    </Slider>
  );
};

export default BannerView;
