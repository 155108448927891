import { createContext, useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import AppRoutes from "./AppRoutes.js";
import { BrowserRouter } from "react-router-dom";

import "react-toastify/dist/ReactToastify.css";
import "./assets/dflip.css";
import "./assets/themify-icons.min.css";
import { client } from "./apollo.js";
import { ApolloProvider } from "@apollo/client";
import SentryFallbackComponent from "components/shared/SentryFallbackComponent.js";
import ModalProvider from "components/shared/ModalProvider.js";

export const AppContext = createContext();

if (process.env.NODE_ENV === "production")
   Sentry.init({
      dsn: process.env.REACT_APP_SENTRY_DSN,
      environment: process.env.NODE_ENV,
      release: `wis@${process.env.REACT_APP_VERSION}`,
      integrations: [new Integrations.BrowserTracing()],
      tracesSampleRate: 0
   });

function App() {
   const [modals, setModals] = useState([]);
   const [user, setUser] = useState(null);

   useEffect(() => {
      if (modals.length > 0) {
         document.body.classList.add("overflow-hidden");
      } else {
         document.body.classList.remove("overflow-hidden");
      }
   }, [modals]);

   return (
      <AppContext.Provider value={{ modals, setModals, user, setUser }}>
         <ApolloProvider client={client}>
            <Sentry.ErrorBoundary fallback={<SentryFallbackComponent />}>
               <BrowserRouter>
                  <ModalProvider>
                     <AppRoutes />
                     <ToastContainer />
                  </ModalProvider>
               </BrowserRouter>
            </Sentry.ErrorBoundary>
         </ApolloProvider>
      </AppContext.Provider>
   );
}

export default App;
