import React from "react";
import { FaPlay } from "react-icons/fa";
import { Button } from "../components/shared/base";
import track from "../utils/track";

function VideoThumb({ video, selectVideo }) {
   return (
      <div className="flex flex-col p-4 mb-4 w-full lg:w-1/2 xl:w-1/3">
         <div
            className="relative video-thumb cursor-pointer"
            onClick={_ => {
               selectVideo();
               track("preview product video", video.name);
            }}
         >
            <div
               className="w-full bg-cover bg-center"
               style={{
                  paddingBottom: "56.25%",
                  backgroundImage: `url(${video.poster})`
               }}
            />
            <div className="video-overlay inset-0 absolute flex items-center justify-center text-white text-4xl opacity-75 hover:opacity-100 animate-ease-2">
               <FaPlay />
            </div>
         </div>

         <span className="text-lg my-4 mb-2 font-bold">{video.name}</span>

         {video.description ? (
            <p className="leading-normal mb-4 text-grey-darker">
               {video.description}
            </p>
         ) : null}

         <div>
            <Button
               onClick={_ => track("download product video", video.name)}
               link={video.previewUri}
               target="_blank"
               download
               title="Download"
            />
         </div>
      </div>
   );
}

export default VideoThumb;
