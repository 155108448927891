import { ApolloClient, InMemoryCache, makeVar, from } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { createUploadLink } from "apollo-upload-client";
import { offsetLimitPagination } from "@apollo/client/utilities";

export const showRootMenu = makeVar(false);
export const shipmentVar = makeVar(null);

export const cache = new InMemoryCache({
   typePolicies: {
      Query: {
         fields: {
            showRootMenu: {
               read() {
                  return showRootMenu();
               }
            },
            shipmentList: offsetLimitPagination(["latestStatusIn", "query"])
         }
      }
   }
});

const httpLinkWithUpload = createUploadLink({
   uri: process.env.REACT_APP_GRAPHQL_URL,
   credentials: "include"
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
   if (graphQLErrors)
      graphQLErrors.map(({ message }) => {
         console.log(`[GraphQL error]: ${message}`);
      });

   if (networkError) console.log(`[Network error]: ${networkError}`);
});

const link = from([errorLink, httpLinkWithUpload]);

export const client = new ApolloClient({
   link,
   cache,
   defaultOptions: {
      query: {
         fetchPolicy: "cache-and-network"
      }
   }
});
