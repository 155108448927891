import React from "react";
import { useQuery, gql } from "@apollo/client";
import Spinner from "components/shared/Spinner";
import Errors from "components/shared/Errors";
import { Link, useNavigate } from "react-router-dom";
import BannerView from "components/BannerView";

const FETCH_COLLECTIONS = gql`
  query FETCH_COLLECTIONS {
    randomPromoImages(count: 10, collectionName: "2024 Lifestyle Images") {
      id
      url
    }
    anyCollection(name: "Catalog 2024") {
      id
      children {
        id
        name
        products: productLines {
          id
          name
          slug
          mainImage(size: "300x300")
          activeForBiz
        }
        children {
          id
          name
          products: productLines {
            id
            name
            slug
            mainImage(size: "300x300")
            activeForBiz
          }
          children {
            id
            name
            products: productLines {
              id
              name
              slug
              mainImage(size: "300x300")
              activeForBiz
            }
          }
        }
      }
    }
  }
`;

const Products = () => {
  const { loading, error, data } = useQuery(FETCH_COLLECTIONS);

  if (loading) return <Spinner />;
  if (error) return <Errors error={error} />;

  const collections = data.anyCollection.children.filter((i) => i.children.length > 0 || i.products.filter((i) => i.activeForBiz).length > 0);
  return (
    <div className="bg-white">
      <BannerView images={data.randomPromoImages} />

      <div className="flex mx-auto container px-8">
        <>
          {/* Sidebar */}
          <div className="pr-4 py-8 border-r space-y-8" style={{ minWidth: 270 }}>
            {collections.map((i) => (
              <CollectionItem node={i} key={i.id} rootItemClasses="font-bold text-xl mt-4" />
            ))}
          </div>

          {/* Product Info */}
          <div className="p-4 pl-8 pr-0 w-full relative space-y-6">
            {collections.map((i) => (
              <MainCollectionItem node={i} key={i.id} rootItemClasses="font-bold text-3xl mt-4" />
            ))}
          </div>
        </>
      </div>
    </div>
  );
};

const ProductItem = ({ product }) => {
  const navigate = useNavigate();
  return (
    <div
      key={product.id}
      id={`product-${product.id}`}
      style={{ flexBasis: 260 }}
      className="hover:text-blue flex flex-col outline-none hover:border-grey-light border-white border-2 cursor-pointer"
      onClick={() => navigate(`/products/${product.slug}`)}
    >
      <div
        className="bg-center bg-no-repeat bg-contain"
        style={{
          backgroundImage: `url(${product.mainImage})`,
          width: 260,
          height: 260,
        }}
      />
      <div className="text-center text-base text-grey-darker hover:text-blue mb-4">{product.name}</div>
    </div>
  );
};

const CollectionItem = ({ node, rootItemClasses }) => {
  const products = node.products.filter((i) => i.activeForBiz);

  if (node.children === 0 && products.length === 0) return null;
  return (
    <div className="node leading-loose">
      <div className={node.children.length > 0 || products.length > 0 ? `font-bold ${rootItemClasses ? "text-2xl" : ""}` : ""}>
        <a className="text-grey-darkest hover:text-blue truncate" href={`#collection-${node.id}`}>
          {node.name}
        </a>
      </div>
      <div className="pl-4">
        {node.children.map((i) => (
          <CollectionItem node={i} key={i.id} />
        ))}
      </div>
      {products.length > 0 && (
        <div className="products pl-4 mt-2">
          {products.map((i) => (
            <div key={i.id}>
              <a className="text-grey-darkest hover:text-blue" href={`#product-${i.id}`}>
                {i.name}
              </a>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

const MainCollectionItem = ({ node, rootItemClasses }) => {
  const products = node.products.filter((i) => i.activeForBiz);

  return (
    <div className="node leading-loose">
      <div
        className={`text-lg font-semibold pb-2 ${node.children.length > 0 && !rootItemClasses ? "" : ""} ${
          products.length > 0 && !rootItemClasses ? "border-b" : "pb-3"
        } ${rootItemClasses}`}
      >
        {node.name}
      </div>
      <div className="children">
        {node.children.map((i) => (
          <MainCollectionItem node={i} key={i.id} />
        ))}
      </div>
      {products.length > 0 && (
        <div className="products flex flex-wrap">
          {products.map((i) => (
            <ProductItem product={i} key={i.id} />
          ))}
        </div>
      )}
    </div>
  );
};

export default Products;
