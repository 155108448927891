import http from "./http.js";

function track(name, content) {
   http.post(
      "/an/",
      { name, content },
      { baseURL: process.env.REACT_APP_SERVER_BASE_URL }
   );
}

export default track;
