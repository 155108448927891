const ItemSize = ({ shape, ballDiameter, cubeX, cubeY, cubeZ, cylindricalHeight, cylindricalDiameter, flatX, flatY, multiSizes, unit }) => {
  if (shape === "ball")
    return (
      <div>
        {ballDiameter} {unit}
      </div>
    );

  if (shape === "cube" && cubeX && cubeY && cubeZ)
    return (
      <div>
        {cubeX} &times; {cubeZ} &times; {cubeY} {unit}
      </div>
    );

  if (shape === "cylindrical")
    return (
      <div>
        Diameter: {cylindricalDiameter} &times; height: {cylindricalHeight} {unit}
      </div>
    );

  if (shape === "flat")
    return (
      <div>
        {flatX} &time; {flatY} {unit}
      </div>
    );

  if (shape === "multi") return <div className=" whitespace-pre-wrap">{multiSizes}</div>;

  return "-";
};

export default ItemSize;
