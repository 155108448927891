const { gql, useQuery } = require("@apollo/client");

const FETCH_RANDOM_IMAGE = gql`
  query FETCH_RANDOM_IMAGE {
    randomPromoImage(collectionName: "2024 Lifestyle Images") {
      id
      url
    }
  }
`;

const useRandomImage = () => useQuery(FETCH_RANDOM_IMAGE);

export default useRandomImage;
