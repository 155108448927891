import { useModals } from "components/shared/ModalProvider";
import QcStatus from "components/shared/QcStatus";
import QCReport from "./QCReport";

const QCReports = ({ qcReports }) => {
   if (qcReports.length === 0) return null;

   const modal = useModals();

   return (
      <div>
         {qcReports.map(qc => (
            <div
               key={qc.id}
               className="flex space-x-4 items-center cursor-pointer border-t border-gray-100 hover:bg-gray-100 active:bg-gray-200 py-2"
               onClick={() =>
                  modal.present({
                     title: "QC Report",
                     maxWidth: "max-w-7xl",
                     children: <QCReport id={qc.id} />
                  })
               }
            >
               <QcStatus status={qc.status} />
               <div>
                  {qc.product.name} &times; {qc.checkQty}
               </div>
            </div>
         ))}
      </div>
   );
};

export default QCReports;
