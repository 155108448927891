import { useState } from "react";
import { BiChevronDown } from "react-icons/bi";

const Dropdown = ({ label, children }) => {
  const [show, setShow] = useState(false);

  return (
    <div className="relative z-50 h-full font-semibold">
      {show && <div className="fixed inset-0" onClick={() => setShow(false)}></div>}
      <div className="flex items-center cursor-pointer h-full" onClick={() => setShow(!show)}>
        <div>{label}</div>
        <div className="pl-1">
          <BiChevronDown size={21} />
        </div>
      </div>
      <div
        className={`absolute right-0 bg-white bg-opacity-80 backdrop-blur shadow-sm rounded-b-lg text-gray-800 ${
          show ? "visible opacity-100" : " invisible opacity-0"
        }`}
        style={{
          transition: `opacity 0.4s cubic-bezier(0.3, 2.1, 0.55, 1) 0s, visibility 0.4s cubic-bezier(0.3, 2.1, 0.55, 1) 0s, transform 0.4s cubic-bezier(0.3, 2.1, 0.55, 1) 0s`,
        }}
        onClick={() => setShow(false)}
      >
        {children}
        {/* {options.map((i) => {
          return (
            <div
              className={`px-6 py-2 border-gray-100 border-t first:border-t-0 hover:text-blue-600 active:text-blue-700 cursor-pointer
              ${value.value === i.value ? "bg-blue-100 text-blue-700" : "bg-white text-gray-800"}
              }
              `}
              key={i.key}
              onClick={() => onChange(i)}
            >
              {i.label}
            </div>
          );
        })} */}
      </div>
    </div>
  );
};

export default Dropdown;
