import { Link, NavLink } from "react-router-dom";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import { IoIosCloseCircle, IoIosCheckmarkCircleOutline, IoIosCheckmarkCircle } from "react-icons/io";
import Icon from "./Icon";
import { InlineSpinner } from "./Spinner";

export const LinkRow = ({ ...rest }) => {
  return <Link className="p-4 px-4 lg:px-6 border-t border-gray-100 flex items-center justify-between animate-ease-2 hover:bg-sky-200" {...rest} />;
};

export const NavigationLink = ({ title, className = "", children, ...rest }) => (
  <NavLink
    className={({ isActive }) =>
      "font-semibold hover:text-sky-600 dark:hover:text-sky-700 " +
      (isActive ? "text-sky-600 dark:text-sky-700" : "text-gray-700 dark:text-gray-300") +
      " " +
      className
    }
    {...rest}
  >
    {title}
    {children}
  </NavLink>
);

export const ButtonWithArrow = ({ className = "", show = false, children, ...rest }) => {
  return (
    <button className={`cursor-pointer flex items-center space-x-2 outline-none focus:outline-none relative max-w-full ${className}`} {...rest}>
      <div className="flex items-center space-x-2 max-w-full">{children}</div>
      <FiChevronRight className={`transform flex-shrink-0 transition-transform ${show ? "rotate-90" : "rotate-0"}`} />
    </button>
  );
};

export const RoundedButton = ({ className = "", disabled = false, loading = false, children, ...rest }) => {
  return (
    <button
      disabled={disabled}
      className={`
            cursor-pointer flex items-center space-x-2 outline-none focus:outline-none relative rounded-full py-2 px-6
            disabled:opacity-80 disabled:cursor-not-allowed
            ${className}`}
      {...rest}
    >
      {loading && (
        <div className={`inline-block align-middle mr-2 text-white ${disabled ? "opacity-50" : ""}`}>
          <InlineSpinner color="#eee" size={21} text={null} />
        </div>
      )}
      <span className="align-middle">{children}</span>
    </button>
  );
};

export const Button = ({
  title,
  onClick,
  link,
  navLink,
  size = "sx",
  bold = false,
  border,
  disabled = false,
  color,
  fontWeight = "font-bold",
  className = "",
  leftIcon = null,
  rightIcon = null,
  iconSize = 16,
  loading = false,
  prev,
  next,
  target,
  ...rest
}) => {
  let textSize = "text-base";
  let padding = "p-0";

  let textColor = "text-sky-600";
  let hoverTextColor = "text-sky-700";
  let activeTextColor = "text-sky-800";
  let spinnerColor = "#00bbd6";

  if (size) textSize = "text-" + size;

  if (color) {
    switch (color) {
      case "red":
        textColor = "text-red-600";
        hoverTextColor = "text-red-500";
        activeTextColor = "text-red-700";
        spinnerColor = "#F56565";
        break;
      case "white":
        textColor = "text-white";
        hoverTextColor = "text-sky-600";
        activeTextColor = "text-sky-700";
        break;
      case "gray":
        textColor = "text-gray-600";
        hoverTextColor = "text-gray-700";
        activeTextColor = "text-sky-900";
        spinnerColor = "#A0AEC0";
        break;
      case "darkGray":
        textColor = "text-gray-700 dark:text-gray-300";
        hoverTextColor = "text-gray-800";
        activeTextColor = "text-sky-900";
        spinnerColor = "#A0AEC0";
        break;
      default:
        // statements_def
        break;
    }
  }

  let borderColor = "border-gray-100";
  let bgColor = "bg-white";

  let hoverBorderColor = "border-gray-100";
  let hoverBgColor = "bg-gray-100";

  let activeBorderColor = "border-gray-100";
  let activeBgColor = "bg-gray-200";

  let disabledBgColor = "border-bg-100";

  if (border) {
    padding = "py-1 px-4";
    if (size) {
      switch (size) {
        case "sm":
          padding = "p-1 px-3";
          break;
        case "lg":
          padding = "py-1 px-6";
          break;
        default:
          break;
      }
    }

    fontWeight = "font-normal";

    textColor = "text-gray-600";
    borderColor = "border-gray-200";
    bgColor = "bg-white";

    hoverTextColor = "text-gray-600";
    hoverBorderColor = "border-gray-100";
    hoverBgColor = "bg-gray-100";

    activeTextColor = "text-gray-600";
    activeBorderColor = "border-gray-200";
    activeBgColor = "bg-gray-200";

    disabledBgColor = "bg-white";

    if (color) {
      switch (color) {
        case "blue":
          textColor = "text-sky-600";
          borderColor = "border-sky-600";
          bgColor = "bg-white dark:bg-gray-800";

          hoverTextColor = "text-white";
          hoverBorderColor = "border-sky-600";
          hoverBgColor = "bg-sky-600";

          activeTextColor = "text-white";
          activeBorderColor = "border-sky-700";
          activeBgColor = "bg-sky-700";

          disabledBgColor = "bg-white";
          break;

        case "red":
          textColor = "text-red-600";
          borderColor = "border-red-600";
          bgColor = "bg-white";

          hoverTextColor = "text-white";
          hoverBorderColor = "border-red-600";
          hoverBgColor = "bg-red-600";

          activeTextColor = "text-white";
          activeBorderColor = "border-red-700";
          activeBgColor = "bg-red-700";

          disabledBgColor = "bg-white";
          break;

        default:
          break;
      }
    }
  }

  if (!bold) fontWeight = "font-normal";
  if ((link || navLink) && !border) padding = "p-0";

  const FinalClassName = `
      flex items-center focus:outline-none
      ${
        border
          ? `
            border ${borderColor}
            hover:${hoverBorderColor}
            active:${activeBorderColor}
            ${bgColor}
            hover:${hoverBgColor}
            active:${activeBgColor}
            disabled:${disabledBgColor}`
          : ""
      } rounded-full outline-none cursor-pointer
      ${fontWeight} ${textSize} ${padding}
      ${textColor}
      hover:${hoverTextColor}
      active:${activeTextColor}
      disabled:opacity-70
      ${disabled ? "cursor-not-allowed" : ""}
      animate-ease-1
      ${className}
   `;

  if (link) {
    const finalLink = target === "_blank" ? link.replace("https:", "").replace("http:", "") : link;
    return (
      <Link className={FinalClassName} to={finalLink} onClick={onClick} target={target} rel={target ? "noopener noreferrer" : null} {...rest}>
        {leftIcon ? <Icon className="mr-2" icon={leftIcon} size={iconSize} /> : null}
        {title}
        {rightIcon ? <Icon className="ml-2" icon={rightIcon} size={iconSize} /> : null}
      </Link>
    );
  }

  if (navLink) {
    return (
      <NavLink className={FinalClassName} to={navLink} {...rest}>
        {leftIcon ? <Icon className="mr-2" icon={leftIcon} size={iconSize} /> : null}
        {title}
        {rightIcon ? <Icon className="ml-2" icon={rightIcon} size={iconSize} /> : null}
      </NavLink>
    );
  }

  return (
    <button disabled={disabled} onClick={onClick} className={FinalClassName} {...rest}>
      {loading && (
        <div className={`inline-block align-middle mr-2 ${disabled ? "opacity-50" : ""}`}>
          <InlineSpinner color={spinnerColor} thickness={1} size={18} text={null} />
        </div>
      )}
      {prev ? <FiChevronLeft size={21} /> : !loading && leftIcon ? leftIcon : null}
      <span className="align-middle">{title}</span>
      {next ? <FiChevronRight size={21} /> : !loading && rightIcon ? rightIcon : null}
    </button>
  );
};

export const ButtonX = ({ onClick, className = "2xl" }) => (
  <div
    className={`
         ${className}
         cursor-pointer
         animate-ease-2 text-red-400 hover:text-red-600 active:text-red-700`}
  >
    <IoIosCloseCircle onClick={onClick} />
  </div>
);

export const CloseButton = ({ className = "", onClick, buttonStyle = "dark" }) => {
  let textColor = "text-gray-700";
  let hoverTextColor = "text-gray-600";
  let activeTextColor = "text-gray-900";
  if (buttonStyle === "light") {
    textColor = "text-white";
    hoverTextColor = "text-gray-300";
    activeTextColor = "text-gray-500";
  }
  return (
    <button
      onClick={onClick}
      className={`
            text-5xl leading-none font-thin
            focus:outline-none border-none cursor-pointer
            ${textColor} hover:${hoverTextColor} active:${activeTextColor}
            animate-ease-1
            ${className}
         `}
      style={{
        transform: "translateY(-3px)",
      }}
    >
      &times;
    </button>
  );
};

export const Tag = ({ title, leftView = null, rightView = null, selected = false, onClick, disabled = false, className = "" }) => {
  const textColor = "text-gray-600 dark:text-gray-300 active:text-white active:dark:text-gray-200 hover:text-white";
  const borderColor = "border-gray-100 dark:border-gray-700 active:border-sky-600 dark:active:border-sky-700 hover:border-sky-600";
  const bgColor = "bg-white dark:bg-gray-800 hover:bg-sky-600 active:bg-sky-700 dark:active:bg-sky-900";

  const selectedTextColor = "text-white text-gray-200";
  const selectedBorderColor = "border-sky-600 dark:border-sky-700";
  const selectedBgColor = "bg-sky-600 dark:bg-sky-900";

  return (
    <button
      disabled={disabled}
      type="button"
      onClick={onClick}
      className={`
            flex items-center
            border
            outline-none
            focus:outline-none whitespace-nowrap
            rounded-full px-4 py-1
            ${
              selected
                ? `
               ${selectedTextColor}
               ${selectedBorderColor}
               ${selectedBgColor}
               `
                : `
               ${textColor}
               ${borderColor}
               ${bgColor}
               `
            }
            ${disabled ? "cursor-not-allowed" : onClick ? "cursor-pointer" : "cursor-default"}
            disabled:opacity-70
            animate-ease-1
            ${className}
         `}
    >
      {leftView}
      <span>{title}</span>
      {rightView}
    </button>
  );
};

export const CheckBox = ({ size = 21, checked = false, onChange }) => {
  return (
    <div
      className="cursor-pointer"
      onClick={(e) => {
        e.preventDefault();
        if (onChange) {
          e.stopPropagation();
          onChange(!checked);
        }
      }}
    >
      {checked ? (
        <IoIosCheckmarkCircle size={size} className="text-green-500 hover:text-gray-500 active:text-gray-300" />
      ) : (
        <IoIosCheckmarkCircleOutline size={size} className="text-gray-300 hover:text-green-400 active:text-green-600" />
      )}
    </div>
  );
};
