import NavigationBar from "components/shared/NavigationBar";
import ProductsView from "./ProductsView";
import ContainerizationView from "components/shared/ContainerizationView";
import Button from "components/shared/Button";
import { useParams } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import Spinner from "components/shared/Spinner";
import Errors from "components/Errors";
import { CANCEL_PRODUCTION_PLAN, COMPUTE_PRODUCTION_PLAN, FETCH_PRODUCTION_PLAN, SAVE_PRODUCTION_PLAN } from "./graphql";
import { useEffect, useState } from "react";
import { Alert } from "components/shared/Toast";
import moment from "moment";

Date.prototype.addDays = function (days) {
  var date = new Date(this.valueOf());
  date.setDate(date.getDate() + days);
  return date;
};

const OrderDetail = () => {
  const { id } = useParams();
  const { loading, error, data } = useQuery(FETCH_PRODUCTION_PLAN, { variables: { id } });

  const [startDate, setStartDate] = useState(moment(new Date().addDays(7)).format("YYYY-MM-DD"));
  const [lines, setLines] = useState([]);
  const [requiredRecompute, setRequiredRecompute] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isComputing, setIsComputing] = useState(false);

  const [saveProductionPlan] = useMutation(SAVE_PRODUCTION_PLAN, {
    onError(error) {
      Alert("error", error.message);
    },
  });
  const [cancelPlan, cancelPlanRes] = useMutation(CANCEL_PRODUCTION_PLAN, {
    onCompleted() {
      Alert("success", "Saved");
    },
    onError(error) {
      Alert("error", error.message);
    },
  });

  const [compute, computeRes] = useMutation(COMPUTE_PRODUCTION_PLAN, {
    onCompleted() {
      Alert("success", "Ready date is computed.");
      setIsComputing(false);
      setRequiredRecompute(false);
    },
    onError(error) {
      Alert("error", error.message);
      setIsComputing(false);
    },
  });

  useEffect(() => {
    if (data) {
      setLines(
        data.productionPlan.lines.map((line) => {
          const qty = Math.ceil(line.qty / line.product.itemsPerSet);
          return { ...line, qty };
        })
      );
      if (data.productionPlan.startDate) setStartDate(moment(data.productionPlan.startDate).format("YYYY-MM-DD"));
    }
  }, [data]);

  function savePlan() {
    const variables = {
      id,
      startDate: new Date(startDate),
      lines: lines.map((i) => ({
        name: i.product.name,
        productOdooId: i.product.odooId,
        qty: i.qty * i.product.itemsPerSet,
      })),
    };
    return new Promise((onCompleted, onError) => saveProductionPlan({ variables, onCompleted, onError }));
  }

  async function saveHandler() {
    setIsSaving(true);
    try {
      await savePlan();
      Alert("success", "Saved");
    } catch (error) {
      Alert("error", error.message);
    } finally {
      setIsSaving(false);
    }
  }

  async function computeHandler() {
    setIsComputing(true);
    try {
      await savePlan();
      compute({ variables: { id } });
    } catch (error) {
      Alert("error", error.message);
    }
  }

  if (loading) return <Spinner />;
  if (error) return <Errors error={error} />;

  return (
    <div className="flex flex-col flex-1">
      <NavigationBar
        title={`${data.productionPlan.name}`}
        rightView={
          data.productionPlan.state === "DRAFT" && (
            <div className="text-base px-4">
              <Button onClick={saveHandler} loading={isSaving} disabled={isSaving || isComputing}>
                Save
              </Button>
            </div>
          )
        }
      />

      <div className="grid gap-8 grid-cols-12 m-10 flex-1">
        <div className="col-span-8 space-y-8">
          <ProductsView
            originalPlan={data.productionPlan}
            lines={lines}
            setLines={setLines}
            startDate={startDate}
            setStartDate={setStartDate}
            requiredRecompute={requiredRecompute}
            setRequiredRecompute={setRequiredRecompute}
            computeHandler={computeHandler}
            computeRes={computeRes}
            isSaving={isSaving}
            isComputing={isComputing}
          />
        </div>

        <div className="col-span-4 space-y-8 flex flex-col">
          <div className="card">
            <h4>Capacity</h4>
            <div className="mt-4">
              <ContainerizationView
                totalCbm={lines.reduce((acc, line) => {
                  return acc + line.totalCbm;
                }, 0)}
              />
            </div>
          </div>

          <div className="flex-1"></div>
          {data.productionPlan.state !== "CANCELED" && (
            <div className="flex justify-end">
              <Button
                danger
                onClick={() => {
                  if (window.confirm("Are you sure you want to cancel this order? This action is irreversible and you will lose all the data."))
                    cancelPlan({ variables: { planId: id, cancelLaterPlans: false } });
                }}
                loading={cancelPlanRes.loading}
              >
                Cancel Order
              </Button>
            </div>
          )}
        </div>
      </div>

      {/* <SummariView /> */}
    </div>
  );
};

export default OrderDetail;
