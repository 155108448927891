import { useQuery, gql } from "@apollo/client";
import Errors from "components/Errors";
import Spinner from "components/shared/Spinner";

const FETCH_QC_REPORT = gql`
   query($id: ID!) {
      qcReport(id: $id) {
         id
         name
         createdAt
         status
         invoice {
            id
            customer {
               id
               name
            }
         }

         product {
            id
            name
            number
            productLine {
               id
               name
            }
         }
         qty
         factory {
            id
            name
         }
         html
      }
   }
`;

const QCReport = ({ id }) => {
   const { loading, error, data } = useQuery(FETCH_QC_REPORT, {
      variables: { id }
   });

   if (loading) return <Spinner />;
   if (error) return <Errors error={error} />;

   return (
      <div className="flex flex-col flex-1 w-full h-full px-10 pb-10">
         <iframe
            title="QC Report"
            className="flex-1 w-full bg-white rounded shadow-sm"
            srcDoc={data.qcReport.html}
            frameBorder="0"
         />
      </div>
   );
};

export default QCReport;
