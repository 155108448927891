import React from "react";
import { Routes, Route } from "react-router-dom";
import Detail from "./detail";
import Shipments from "./Shipments";

const ShipmentsPage = () => {
  return (
    <Routes>
      <Route index element={<Shipments />} />
      <Route path=":shipmentId/:invoiceId" element={<Detail />} />
    </Routes>
  );
};

export default ShipmentsPage;
