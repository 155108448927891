import PageTitle from "components/PageTitle";

export default () => (
  <div className="flex-col flex-1 px-4 md:px-0">
    <PageTitle title="Terms of Use and Privacy Policy" />

    <div className="container mx-auto max-w-5xl p-8 py-10 leading-relaxed text-base">
      <div className="space-y-4">
        <p>
          <strong>Last Updated:</strong> 2024-04-11
        </p>

        <p>
          Welcome to biz.waboba.com. This document outlines our Terms of Use and Privacy Policy, which govern your access to and use of biz.waboba.com and its
          content, products, and services. By accessing or using the website, you agree to be bound by these terms and policies.
        </p>
      </div>

      <hr />

      <div className="space-y-4">
        <h2 className="pb-4">Terms of Use</h2>

        <h3>1. Introduction</h3>
        <p>
          Biz.waboba.com is dedicated to sharing product information, including but not limited to, sizes, weights, images, marketing materials, and shipment
          information, to facilitate informed decisions by our customers.
        </p>

        <h3>2. Intellectual Property Rights</h3>
        <p>
          Unless otherwise stated, biz.waboba.com and/or its licensors own the intellectual property rights for all material on biz.waboba.com. All intellectual
          property rights are reserved. You may access this from biz.waboba.com for your own personal use subjected to restrictions set in these terms and
          conditions.
        </p>

        <h3>3. User Conduct</h3>
        <p>
          Users are expected to use the website responsibly and ethically. The following uses are prohibited: uploading malicious content, infringing on
          intellectual property, conducting or soliciting illegal activity, and any actions that could harm the site or its users.
        </p>

        <h3>4. Disclaimer</h3>
        <p>
          The materials on biz.waboba.com are provided on an 'as is' basis. [Company Name] makes no warranties, expressed or implied, and hereby disclaims and
          negates all other warranties including, without limitation, implied warranties or conditions of merchantability, fitness for a particular purpose, or
          non-infringement of intellectual property or other violation of rights.
        </p>

        <h3>5. Limitation of Liability</h3>
        <p>
          In no event shall [Company Name] or its suppliers be liable for any consequential loss suffered or incurred by you or any third party arising from the
          use or inability to use this website, even if [Company Name] or an authorized representative has been notified, orally or in writing, of the
          possibility of such damage.
        </p>
      </div>

      <hr />

      <div className="space-y-4">
        <h2 className="pb-4">Privacy Policy</h2>

        <h3>1. Information We Collect</h3>
        <p>
          Our collection of information includes information you provide directly to us, information collected automatically through your use of our site, and
          information obtained from third parties such as marketing partners.
        </p>

        <h3>2. How We Use Your Information</h3>
        <p>
          Your information helps us personalize and continually improve your experience at biz.waboba.com. Uses include order processing, delivery of products
          or services, communication regarding orders or services, and internal analysis for improving our offerings.
        </p>

        <h3>3. Information Sharing and Disclosure</h3>
        <p>
          We do not sell, rent, or otherwise share your personal information with third parties except as necessary to fulfill your requests, such as to ship an
          order, or as required by law.
        </p>

        <h3>4. Data Security</h3>
        <p>
          We implement a variety of security measures to maintain the safety of your personal information. However, no method of transmission over the Internet,
          or method of electronic storage, is 100% secure. While we strive to use commercially acceptable means to protect your personal information, we cannot
          guarantee its absolute security.
        </p>

        <h3>5. Your Rights</h3>
        <p>
          You have the right to access, correct, delete, or limit the use of your personal data. If you wish to exercise these rights, please contact us at the
          details provided below.
        </p>

        <h3>6. Changes to This Policy</h3>
        <p>
          We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. You are advised
          to review this Privacy Policy periodically for any changes.
        </p>

        <h3>Contacting Us</h3>
        <p>If you have any questions about these Terms of Use and Privacy Policy, please contact us:</p>
        <p>
          <strong>Email:</strong> <a href="mailto:legal@waboba.com">legal@waboba.com</a>
        </p>
      </div>

      <hr />
    </div>
  </div>
);
