import React from "react";
import { useQuery, gql } from "@apollo/client";
import { Button } from "components/shared/base";
import Spinner from "components/shared/Spinner";
import Errors from "components/shared/Errors";
import http from "../utils/http";
import PageTitle from "components/PageTitle";

const FETCH_PROMO_IMAGES = gql`
  query FETCH_PROMO_IMAGES {
    images: allPromoImages(collectionName: "2024 Lifestyle Images") {
      id
      thumb: url(size: "300x300")
      url
    }
  }
`;

const LifestyleImages = () => {
  const { loading, error, data } = useQuery(FETCH_PROMO_IMAGES);

  if (loading) return <Spinner />;
  if (error) return <Errors error={error} />;

  return (
    <div>
      <PageTitle title="Lifestyle Images" />
      <div className="flex flex-wrap items-baseline justify-center my-6">
        {data.images.map((image, index) => (
          <div key={index} className="mx-6 mb-6 flex flex-col items-center">
            <div
              style={{ backgroundImage: "url(" + image.thumb + ")" }}
              className={`mb-4 w-48 h-48 bg-contain bg-center bg-no-repeat
                     ${process.env.NODE_ENV === "development" ? " bg-gray-100" : ""}`}
            />
            <Button
              title="download"
              link={image.url}
              target="_blank"
              rel="noopener noreferrer"
              onClick={(_) => {
                http.post("/an/", {
                  name: "download promo image",
                  content: image.url,
                });
              }}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default LifestyleImages;
