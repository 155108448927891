import React from "react";
import { Link } from "react-router-dom";

const Footer = () => (
  <footer className="p-12 bg-gray-100">
    <div className="mx-auto container text-center space-y-4">
      <div className="mb-4">
        <p className="mb-4 opacity-75">
          To learn more about Waboba, please visit{" "}
          <a className="hover:text-grey-dark underline" href="https://waboba.com" target="_blank" rel="noopener noreferrer">
            waboba.com
          </a>
        </p>
      </div>

      <div>
        <Link className="underline" to="/terms-privacy">
          Terms of Use and Privacy Policy
        </Link>
      </div>

      <div className="text-xs opacity-50">
        <p>Copyright &copy; {new Date().getFullYear()} Waboba. All rights reserved.</p>
        <p>Version {process.env.REACT_APP_VERSION}</p>
      </div>
    </div>
  </footer>
);

export default Footer;
