import axios from 'axios';

const instance = axios.create({
   baseURL: process.env.REACT_APP_SERVER_BASE_URL,
   timeout: 60000,
   withCredentials: true,
});

instance.interceptors.request.use(
   (config) => {
      // Do something before request is sent
      // const token = localStorage.getItem("jwt");
      // if (token) config.headers.authorization = "JWT " + token;
      if (process.env.NODE_ENV === 'development') {
         console.groupCollapsed(
            `%cRequesting... [${config.method.toUpperCase()}] ${config.url}`,
            'color: #f6993f; font-weight: bold'
         );
         console.log(
            '%cRequest Data',
            'color: #3490dc; font-weight: bold',
            config.data
         );
         console.log('%cConfig', 'color: #3490dc; font-weight: bold', config);
         console.groupEnd();
      }
      return config;
   },
   (error) => {
      // Do something with request error
      console.dir(
         '%cRequest error:',
         'color: #e3342f; font-weight: bold',
         error
      );
      return Promise.reject(error);
   }
);

instance.interceptors.response.use(
   (res) => {
      if (process.env.NODE_ENV === 'development') {
         console.groupCollapsed(
            `%c[${res.config.method.toUpperCase()}] ${res.config.url} ${res.status
            } (${res.statusText})`,
            'color: #f6993f; font-weight: bold'
         );
         console.log(
            '%cResponse Data',
            'color: #3490dc; font-weight: bold',
            res.data
         );
         console.log('%cResponse', 'color: #3490dc; font-weight: bold', res);
         console.groupEnd();
      }
      return res;
   },
   (err) => {
      console.warn(
         '%c[http.js:31] response error',
         'color: #3490dc; font-weight: bold',
         err.response
      );
      if (err.response) {
         console.groupCollapsed(
            `%c[${err.response.config.method.toUpperCase()}] ${err.response.config.url
            } ${err.response.status} (${err.response.statusText})`,
            'color: #e3342f; font-weight: bold'
         );
         console.info(err.response);
         console.groupEnd();
         const res = err.response;
         if (res.data.errors) {
            err.errors = res.data.errors;
         }
         switch (res.status) {
            case 400:
               err.message = res.statusText;
               if (res.data.message) {
                  err.message = res.data.message;
               } else if (res.data.non_field_errors) {
                  err.message = res.data.non_field_errors.join(',');
               } else if (Array.isArray(res.data)) {
                  err.message = res.data.join(',');
               } else {
                  let msg = '';
                  for (let i in res.data) {
                     msg += i + ' ' + res.data[i];
                  }
                  err.message = msg;
               }
               break;
            case 403:
               err.message = res.data.detail;
               break;
            case 404:
               err.message = '404 Not Found.';
               break;
            case 500:
               err.message = '500 Server Error';
               break;
            default:
               err.message = 'Unknown Error..';
         }
      } else {
         err.message = 'Server is not responding...';
      }
      return Promise.reject(err);
   }
);

export function download(url, filename) {
   instance
      .get(url, { timeout: 60000, responseType: 'blob' })
      .then((res) => {
         const url = window.URL.createObjectURL(new Blob([res.data]));
         const link = document.createElement('a');
         link.href = url;
         link.setAttribute('download', filename);
         document.body.appendChild(link);
         link.click();
         document.body.removeChild(link);
      })
      .catch((err) => {
         alert(err);
      });
}

export default instance;
