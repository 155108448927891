import React from "react";
import { Button } from "../components/shared/base";
import PageTitle from "../components/PageTitle";
import track from "../utils/track";

const PDFItem = ({ title, bg, url }) => (
  <div className="mb-8 mx-auto text-center">
    <div style={{ maxWidth: 320 }}>
      <img src={bg} alt="Catalog cover" />
    </div>

    <div className="text-center p-4 text-xl">{title}</div>

    <div className="_df_button rounded-full mb-4 bg-sky-600 hover:bg-sky-500 border-0" source={url}>
      View
    </div>

    <div className="flex justify-center">
      <Button
        className="text-blue hover:text-grey-dark"
        onClick={(_) => track("download marketing materials", title)}
        link={url}
        target="_blank"
        rel="noopener noreferrer"
        title="Download PDF"
      />
    </div>
  </div>
);

const Marketing = () => {
  return (
    <div>
      <PageTitle title="Sales & Marketing Resources" subtitle="Check out the PDF's below for some marketing ideas" />

      <div className="flex flex-col md:flex-row justify-around mx-auto mt-16 max-w-5xl">
        <PDFItem
          title="Marketing Guide"
          url="https://cdn.waboba.com/assets/marketing/Waboba_MarketingGuide_lores.pdf?v=2"
          bg="https://cdn.waboba.com/assets/marketing/marketing-guide.jpg"
        />
        <PDFItem
          title="Product Guide"
          url="https://cdn.waboba.com/assets/marketing/Waboba_ProductDeck_lores.pdf?v=2"
          bg="https://cdn.waboba.com/assets/marketing/product-guide.jpg"
        />
        <PDFItem
          title="Sales Guide"
          url="https://cdn.waboba.com/assets/marketing/Waboba_Sell_In_Deck_lores.pdf?v=2"
          bg="https://cdn.waboba.com/assets/marketing/sales-guide.jpg"
        />
      </div>
    </div>
  );
};
export default Marketing;
