import { useState } from "react";
import { useParams } from "react-router-dom";
import { useQuery, gql } from "@apollo/client";
import VideoThumb from "components/VideoThumb";
import Spinner from "components/shared/Spinner";
import Errors from "components/shared/Errors";
import Button from "components/shared/Button";
import http from "utils/http.js";
import ProductData from "./ProductData";
import PromoteImages from "./PromoImages";
import TestReportView from "./TestReportsView";
import NavigationBar from "components/shared/NavigationBar";
import { Alert } from "components/shared/Toast";

const FETCH_PRODUCT_LINE_DETAIL = gql`
  query FETCH_PRODUCT_LINE_DETAIL($slug: String!) {
    productLine(slug: $slug) {
      id
      name
      slug
      description
      age
      hsCode
      hsCodeForEu
      hsCodeForUs
      pantone
      mainImage
      materials {
        material {
          id
          name
        }
      }
      children {
        id
        materials {
          material {
            id
            name
          }
        }
        pantone
      }
      productList: fromProductList {
        id
        testReports {
          id
          standard {
            id
            name
          }
          fileList
        }
      }
      newImages(stage: "biz") {
        id
        name
        url
        thumb: url(size: "300x300")
        stage
      }
      tiffImages: newImages(stage: "biz", mimeTypes: ["image/tiff"]) {
        id
        name
        url
      }
      videos {
        id
        poster
        previewUri
        name
      }
      logos {
        id
        url(size: "400x400")
        name
      }
    }
    items: customerProducts(productLineSlug: $slug) {
      id
      name
      number
      shape
      ballDiameter
      cubeX
      cubeY
      cubeZ
      cylindricalHeight
      cylindricalDiameter
      flatX
      flatY
      multiSizes
      itemWeight
      hsCode
      productMaterial
      pantone: finalPantone
      moq
      packing
      barCode
      innerBoxBarcodeEan
      outerCartonBarcodeEan
      innerBoxBarcodeUpc
      outerCartonBarcodeUpc
      innerBoxX
      innerBoxY
      innerBoxZ
      displayBoxX
      displayBoxY
      displayBoxZ
      innerCartonX
      innerCartonY
      innerCartonZ
      outerCartonX
      outerCartonY
      outerCartonZ
      quantityPerDisplayBox
      quantityPerCarton
      ctnNetWeight
      ctnGrossWeight
      outerCartonCbm
    }
  }
`;

const PACKAGE_NAMES = ["displaybox", "innerbox", "package", "cardedblister", "blister", "warp"];

function isPackages(item) {
  return PACKAGE_NAMES.some((i) => item.name.toLowerCase().includes(i));
}

function isItem(item) {
  return !isPackages(item);
}

const ProductDetailPage = () => {
  const { slug } = useParams();
  const [previewVideo, setPreviewVideo] = useState(null);
  const [previewVideoPoster, setPreviewVideoPoster] = useState(null);
  const { loading, error, data } = useQuery(FETCH_PRODUCT_LINE_DETAIL, {
    variables: { slug },
  });

  if (loading) return <Spinner />;
  if (error) return <Errors error={error} />;

  const product = data.productLine;

  const jpgImages = product.newImages.map((i) => {
    return { ...i, name: i.name.split(".")[0] };
  });

  const itemImages = jpgImages.filter(isItem).map((i) => {
    const tiff = product.tiffImages.find((x) => x.name.includes(i.name));
    return { ...i, tiff };
  });
  const packageImages = jpgImages.filter(isPackages).map((i) => {
    const tiff = product.tiffImages.find((x) => x.name.includes(i.name));
    return { ...i, tiff };
  });

  function addToCart() {
    Alert("success", "240 items added to cart");
  }

  return (
    <div>
      {previewVideo ? (
        <div className="fixed z-50 inset-0 bg-white flex items-center justify-center">
          <span className="cursor-pointer hover:text-blue absolute top-0 right-0 p-4" onClick={() => setPreviewVideo(null)}>
            <span className="text-3xl">✕</span>
          </span>
          <span className="mx-auto max-w-6xl">
            <video className="max-w-full" autoPlay controls src={previewVideo} poster={previewVideoPoster} />
          </span>
        </div>
      ) : null}

      <div className="flex flex-col">
        <NavigationBar title={product.name} />

        <div className="py-10 bg-white">
          <div className="mx-auto container px-10 flex space-x-8 text-lg">
            <div className="flex-1 pr-8 pb-16">
              <h1>{product.name}</h1>
              <div className="mt-8 whitespace-pre-wrap flex-1">{product.description ? product.description : "More information coming soon!"}</div>
            </div>

            {product.mainImage ? (
              <div className=" w-1/3 flex-shrink-0">
                <img className="w-full" src={product.mainImage} alt={product.name} />
              </div>
            ) : null}
          </div>
        </div>

        {data.items.length > 0 ? (
          <div className="my-8 container mx-auto px-8">
            <ProductData items={data.items} productLine={data.productLine} />
          </div>
        ) : null}

        <ProductImages images={itemImages} title="Items" />

        <ProductImages images={packageImages} title="Package" />

        <ProductMedia images={product.packageImages} title="Product Packaging" />

        {product.videos.length > 0 ? (
          <div className="container px-8 mx-auto mt-8">
            <h2 className="uppercase">Promo Videos</h2>
            <div className="flex flex-wrap mt-8 -mx-4">
              {product.videos.map((video) => (
                <VideoThumb
                  key={video.id}
                  video={video}
                  selectVideo={(_) => {
                    setPreviewVideo(video.previewUri);
                    setPreviewVideoPoster(video.poster);
                  }}
                />
              ))}
            </div>
          </div>
        ) : null}

        <TestReportView product={product} />

        <PromoteImages slug={slug} title="Promo Images" />

        {product.logos.length > 0 && <ProductMedia images={product.logos} title="Logos" />}
      </div>
    </div>
  );
};

const ProductImages = ({ images, title }) => {
  return (
    <div className="py-16 border-t border-gray-100 bg-white">
      <div className="mx-auto container px-8">
        <h2 className="uppercase">{title}</h2>
        <div className="grid grid-cols-4 2xl:grid-cols-6 gap-8">
          {images.map((image) => (
            <div key={image.id} className="flex flex-col p-4">
              <span className="flex items-center justify-center" style={{ minHeight: 250 }}>
                <img height={200} width={200} src={image.thumb} alt={image.name} />
              </span>

              <div className="text-center mb-4 text-xs break-words">{image.name}</div>

              <div className="flex flex-col items-center">
                {image.url ? (
                  <a target="_blank" className="font-semibold text-sky-600" rel="noopener noreferrer" href={image.url} download>
                    Download JPG
                  </a>
                ) : null}
                {image.tiff ? (
                  <a target="_blank" className="font-semibold text-sky-600" rel="noopener noreferrer" href={image.tiff.url} download>
                    Download TIF
                  </a>
                ) : null}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const ProductMedia = ({ images, title }) => {
  if (!images || !images.length) return null;

  return (
    <div className="py-16 border-b">
      <div className="mx-auto container px-8">
        <h2 className="uppercase">{title}</h2>
        <div className="flex flex-wrap -mx-4">
          {images.map((image, index) => (
            <div className="flex flex-col p-4" key={index}>
              <span className="flex items-center justify-center" style={{ minHeight: 250 }}>
                <img height={250} width={250} src={image.thumb} alt={image.name} />
              </span>

              <div className="text-center mb-4 text-xs">{image.name}</div>

              <div className="flex flex-col items-center">
                {image.jpg ? (
                  <Button
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={(_) => {
                      http.post("/an/", {
                        name: "download product jpg image",
                        content: JSON.stringify({
                          title,
                          src: image.jpg,
                        }),
                      });
                    }}
                    link={image.jpg}
                    title="Download JPG"
                  />
                ) : null}
                {image.tiff ? (
                  <Button
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={(_) => {
                      http.post("/an/", {
                        name: "download product tif image",
                        content: JSON.stringify({
                          title,
                          src: image.tiff,
                        }),
                      });
                    }}
                    link={image.tiff}
                    title="Download TIF"
                  />
                ) : null}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ProductDetailPage;
