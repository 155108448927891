import { gql, useQuery } from "@apollo/client";
import Errors from "components/Errors";
import SearchBar from "components/shared/SearchBar";
import Spinner from "components/shared/Spinner";
import { useState } from "react";

const FETCH_PRODUCTS_FOR_ORDER_PLANNER = gql`
  query FETCH_PRODUCTS_FOR_ORDER_PLANNER {
    me {
      mainCustomer {
        id
        name
        priceList {
          id
          name
          latestPrices {
            id
            product {
              id
              name
              number
              itemsPerSet
              cbm: outerCartonCbm
              odooId
              productLine {
                id
                name
                slug
                image: bizPrimaryImage(size: "300x300")
              }
            }
          }
        }
      }
    }
  }
`;

const ChooseProduceView = ({ onSelect }) => {
  const [searchText, setSearchText] = useState("");
  const { loading, error, data } = useQuery(FETCH_PRODUCTS_FOR_ORDER_PLANNER);

  if (loading) return <Spinner />;
  if (error) return <Errors errors={error} />;

  const products =
    data.me.mainCustomer?.priceList?.latestPrices
      ?.map((i) => i.product)
      .filter((i) => {
        const cleanedSearchText = searchText.trim().toLowerCase();
        return i.name.toLowerCase().includes(cleanedSearchText) || i.number.toLowerCase().includes(cleanedSearchText);
      })
      .sort((a, b) => a.name.localeCompare(b.name)) ?? [];

  return (
    <div>
      <div>
        <SearchBar placeholder="Search by name or sku." value={searchText} onChange={setSearchText} />
      </div>
      <div className="grid grid-cols-6 gap-6 mt-4">
        {products.map((product, index) => (
          <div key={index} className=" transition-all scale-100 hover:scale-105 duration-100 cursor-pointer" onClick={() => onSelect(product)}>
            <img src={product.productLine.image} alt={product.name} />
            <div className="text-center mt-2 space-y-1">
              <div className="text-sm font-semibold">{product.number}</div>
              <div>{product.name}</div>
              <div className=" opacity-70">{product.itemsPerSet}/set</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ChooseProduceView;
