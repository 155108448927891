import React from "react";
import { useQuery, gql } from "@apollo/client";
import Spinner from "../../components/shared/Spinner";
import Errors from "../../components/shared/Errors";
import { Button } from "../../components/shared/base";
import http from "../../utils/http.js";

const FETCH_PRODUCT_LINE_PROMO_IMAGES = gql`
  query fetchProductLinePromoImages($slug: String!) {
    product: productLine(slug: $slug) {
      id
      images: promoImages {
        id
        url
        thumb: url(size: "400x400")
      }
    }
  }
`;

const PromoteImages = ({ slug, title }) => {
  const { loading, error, data } = useQuery(FETCH_PRODUCT_LINE_PROMO_IMAGES, {
    variables: { slug },
  });

  if (loading) return <Spinner />;
  if (error) return <Errors error={error} />;

  if (data.product.images.length === 0) return null;

  return (
    <div className="grid sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-4">
      {data.product.images
        .filter((i) => i.uri !== null)
        .map((i, index) => (
          <div key={index} className="relative">
            <div
              className="flex bg-grey bg-no-repeat bg-cover relative w-full"
              style={{
                paddingBottom: "75%",
                backgroundImage: `url(${i.thumb})`,
              }}
            />
            <div className="absolute bottom-0 right-0">
              <Button
                color="white"
                style={{ textShadow: "0 0px 5px #000" }}
                target="_blank"
                rel="noopener noreferrer"
                className="p-2 text-white hover:text-blue cursor-pointer"
                download
                onClick={(_) => {
                  http.post("/an/", {
                    name: "download promo image",
                    content: i.url,
                  });
                }}
                link={i.url}
                title="Download"
              />
            </div>
          </div>
        ))}
    </div>
  );
};

export default PromoteImages;
